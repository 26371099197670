<script setup>
  import { useReportsStore } from '../store'
  import { styles } from './gMapStyles'
  import { useThemeStore } from '@/stores/theme'
  import { computed, ref, watch } from 'vue'

  // Store
  const reportsStore = useReportsStore()
  const themeStore = useThemeStore()

  // Refs
  const mapRef = ref(null)
  const internalZoom = ref(reportsStore.dynamicZoom)

  // Computed
  const dynamicZoom = computed({
    get: () => internalZoom.value,
    set: newZoom => {
      internalZoom.value = newZoom
      reportsStore.setDynamicZoom(newZoom)
    },
  })
  const center = computed(() => reportsStore.center)
  const markers = computed(() => reportsStore.markers)
  const mapType = computed(() => reportsStore.mapType)
  const currentTheme = computed(() => themeStore.currentTheme)

  // Watch
  watch(
    () => reportsStore.dynamicZoom,
    newZoom => {
      const mapInstance = mapRef.value?.$mapObject
      if (mapInstance && mapInstance.getZoom() !== newZoom) {
        mapInstance.setZoom(newZoom)
      }
      internalZoom.value = newZoom
    },
    { immediate: true },
  )

  // Methods
  // const handleMarkerClick = marker => {
  //   reportsStore.setCurrentReport(marker.report)
  //   reportsStore.showReportDetails()
  // }

  const setIconByLetraClasificion = letraClasificacion => {
    return `/img/icons/${letraClasificacion}.svg`
  }

  const onZoomChanged = () => {
    const mapInstance = mapRef.value?.$mapObject
    if (mapInstance) {
      const newZoom = mapInstance.getZoom()
      internalZoom.value = newZoom
      reportsStore.setDynamicZoom(newZoom)
    }
  }
</script>

<template>
  <v-card height="100vh">
    <GMapMap
      ref="mapRef"
      :center="center"
      :zoom="dynamicZoom"
      style="width: 100%; height: 100vh"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        mapTypeId: mapType,
        styles:
          currentTheme == 'myCustomDarkTheme' ? styles.dark : styles.light,
      }"
      @zoom_changed="onZoomChanged"
    >
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        :icon="
          m.type == 'temporary'
            ? {
                url: '/img/icons/mapprimary.svg',
              }
            : {
                url: setIconByLetraClasificion(
                  m.report.dgaReport.letraClasificacion,
                ),
              }
        "
      >
        <GMapInfoWindow>
          <v-card
            elevation="0"
            color="white"
          >
            <p
              class="pa-0 ma-0"
              :class="
                currentTheme == 'myCustomDarkTheme'
                  ? ' text-black'
                  : 'black-text'
              "
            >
              {{ m.report.dgaReport.fuenteAgua }} /
              {{ m.report.dgaReport.codigoExpediente }}
            </p>
          </v-card>
        </GMapInfoWindow>
      </GMapMarker>
    </GMapMap>
  </v-card>
</template>

<style>
  .gm-ui-hover-effect {
    display: none !important;
  }

  .text-white {
    color: white;
  }
</style>
