<script setup>
  import { computed, onMounted } from 'vue'
  import { useReportsStore } from './store'

  import LoadingCircle from '@/components/common/LoadingCircle.vue'

  import GMap from './components/GMap.vue'
  import ReportsList from './components/ReportsList.vue'
  import ReportDetails from './components/ReportDetails.vue'

  const reportsStore = useReportsStore()

  const { toggleReportsList } = reportsStore

  const isLoading = computed(() => reportsStore.isLoading)

  const isReportsListVisible = computed(() => reportsStore.isReportsListVisible)
  const isReportDetailsVisible = computed(
    () => reportsStore.isReportDetailsVisible,
  )

  onMounted(async () => {
    if (reportsStore.reports.length === 0) {
      await reportsStore.fetchReports()
    }
  })
</script>

<template>
  <div>
    <div
      v-show="isLoading"
      class="text-center"
    >
      <LoadingCircle />
    </div>

    <div v-show="!isLoading">
      <GMap />
      <ReportsList v-if="isReportsListVisible" />
      <ReportDetails v-if="isReportDetailsVisible" />
      <v-fab
        color="primary"
        icon="mdi-map"
        class="ms-4 mb-4"
        location="bottom start"
        size="64"
        absolute
        app
        appear
        @click.stop="toggleReportsList()"
      />
    </div>
  </div>
</template>
