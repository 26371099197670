import { defineStore } from 'pinia'

import AuthState from './authState'
import AuthActions from './authActions'
import AuthGetters from './authGetters'

export const useAuthStore = defineStore('auth', {
  state: AuthState,
  actions: AuthActions,
  getters: AuthGetters,
})
