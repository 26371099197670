<script setup></script>

<template>
  <div class="full">
    <v-card
      class="ma-auto d-flex justify-center align-center"
      width="80%"
      height="600px"
    >
      <div>
        <v-img
          height="300px"
          width="400px"
          src="/img/icons/draw404.svg"
        />
        <h3 class="text-center my-5">
          Sin autorización
          <v-btn
            to="/"
            text="Volver"
            variant="text"
          />
        </h3>
      </div>
    </v-card>
  </div>
</template>
<style>
  .full {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
</style>
