// stores/theme.js
import { defineStore } from 'pinia'

export const useThemeStore = defineStore('theme', {
  state: () => ({
    currentTheme: 'myCustomLightTheme',
  }),
  actions: {
    toggleTheme() {
      this.currentTheme =
        this.currentTheme === 'myCustomLightTheme'
          ? 'myCustomDarkTheme'
          : 'myCustomLightTheme'
    },
    setTheme(theme) {
      this.currentTheme = theme
    },
  },
})
