export default () => ({
  user: null,
  errorPassLogin: false,
  errorPassLoginMsg: '',
  isOffline: false,
  userProfile: {
    id: '',
    name: '',
    email: '',
  },
  errorLoginMsg: '',
  activeUpdateUser: '',
})
