<script setup>
  import { onMounted } from 'vue'
  import { useReportsStore } from './store'
  import { computed, ref } from 'vue'
  import LoadingCircle from '@/components/common/LoadingCircle.vue'

  import ReportsSync from './components/ReportsSync.vue'
  //store()
  const reportsStore = useReportsStore()

  //data()
  const report = ref({})
  const selectTimestampGroup = ref('')
  //computed()
  const filteredReports = computed(() => reportsStore.getReportsByLocation())
  const isReportsSyncVisible = computed(() => reportsStore.isReportsSyncVisible)
  const selectGroupTimestamp = computed(() => reportsStore.selectGroupTimestamp)
  const search = computed({
    get: () => reportsStore.search,
    set: value => (reportsStore.search = value),
  })
  const isLoading = computed(() => reportsStore.isLoading)

  //methods()
  const btnViewReportById = id => {
    reportsStore.showReportDetails()

    report.value = reportsStore.reports.find(report => report.id == id)
  }
  const btnUpdateReport = () => {
    reportsStore.updateReport(report.value)
  }
  const toggleReportsSync = () => {
    reportsStore.toggleReportsSync()
  }
  const onSelectChangeGroup = () => {
    reportsStore.selectReportsByTimestamp(selectTimestampGroup.value)
  }

  const btnUpdateSelectGroupReport = () => {
    reportsStore.updateReportsByTimestamp(selectTimestampGroup.value)
  }

  //lifecycle()
  onMounted(async () => {
    if (reportsStore.reports.length === 0) {
      await reportsStore.fetchReports()
      selectTimestampGroup.value = selectGroupTimestamp.value
    }
  })
</script>
<template>
  <div>
    <div
      v-show="isLoading"
      class="text-center"
    >
      <LoadingCircle />
    </div>

    <div v-show="!isLoading">
      <ReportsSync v-if="isReportsSyncVisible" />

      <v-row class="mt-14">
        <v-col :cols="!isReportsSyncVisible ? '3' : '11'">
          <!-- lista de reportes -->

          <div class="d-flex">
            <v-text-field
              v-model="search"
              class="mx-4 mt-4"
              density="comfortable"
              menu-icon=""
              placeholder="Buscar por comuna"
              item-title="location"
              prepend-inner-icon="mdi-magnify"
              style="max-width: 350px"
              theme="light"
              variant="solo"
              auto-select-first
              item-props
              rounded
            />

            <v-btn
              icon="mdi-home"
              class="mt-3 mx-2"
              elevation="0"
              to="/"
            />
            <v-btn
              v-if="!isReportsSyncVisible"
              icon="mdi-sync"
              class="mt-3 mx-2"
              elevation="0"
              @click="toggleReportsSync"
            />
          </div>
          <div class="d-flex">
            <v-spacer />
            <v-select
              v-if="reportsStore.groupReports.length > 0"
              v-model="selectTimestampGroup"
              label="Historial de importaciones"
              variant="solo"
              :items="reportsStore.groupReports"
              item-title="timestamp"
              item-value="timestamp"
              @update:model-value="onSelectChangeGroup"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :subtitle="
                    item.raw.timestamp == selectGroupTimestamp ? 'Activo' : ''
                  "
                ></v-list-item>
              </template>
            </v-select>
            <v-btn
              v-if="selectTimestampGroup != selectGroupTimestamp"
              icon="mdi-database-check"
              class="mx-2"
              elevation="0"
              @click="btnUpdateSelectGroupReport"
            />
          </div>
          <v-card
            height="88vh"
            color="background"
            class="overflow-auto"
            elevation="0"
          >
            <v-card
              v-for="report in filteredReports"
              :key="report.id"
              class="mx-3 my-4"
            >
              <v-card-item :title="report.dgaReport.subCuenca">
                <template v-slot:subtitle>
                  <v-icon
                    class="me-1 pb-1"
                    color="error"
                    icon="mdi-alert"
                    size="18"
                  ></v-icon>
                  Nivel normales
                </template>
              </v-card-item>

              <v-card-text class="py-0"> </v-card-text>

              <div class="d-flex py-3">
                <v-list-item
                  density="compact"
                  prepend-icon="mdi-water"
                >
                  <v-list-item-subtitle class=""> </v-list-item-subtitle>
                </v-list-item>

                <v-list-item
                  density="compact"
                  prepend-icon="mdi-ruler"
                >
                  <v-list-item-subtitle> </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  density="compact"
                  prepend-icon="mdi-waves"
                >
                  <v-list-item-subtitle> </v-list-item-subtitle>
                </v-list-item>
              </div>

              <v-divider />

              <v-card-actions>
                <v-btn
                  text="Ver reporte"
                  @click="btnViewReportById(report.id)"
                />
              </v-card-actions>
            </v-card>
          </v-card>
        </v-col>
        <v-col
          v-if="!isReportsSyncVisible"
          cols="9"
        >
          <v-card
            height="96dvh"
            class="my-3 mr-6 overflow-auto"
          >
            <div class="d-flex title-actions">
              <v-card-title>Administracion del reporte</v-card-title>
              <v-spacer />

              <v-btn
                v-if="report.id"
                color="primary"
                class="ma-3"
                @click="btnUpdateReport"
                >Actualizar</v-btn
              >
            </div>
            <v-card-text
              v-if="report.id"
              class="d-flex justify-end"
            >
              <v-row>
                <!-- <v-col
                  v-for="(value, key) in report"
                  :key="key"
                  cols="3"
                >
                  <v-text-field
                    v-if="
                      typeof value === 'string' || typeof value === 'number'
                    "
                    :label="key"
                    v-model="report[key]"
                  />
                </v-col> -->
                <!-- <v-col
                  v-for="(value, key) in report.aggregatedData"
                  :key="key"
                  cols="6"
                >
                  <v-text-field
                    v-if="
                      typeof value === 'string' || typeof value === 'number'
                    "
                    :label="key"
                    v-model="report.aggregatedData[key]"
                  />
                </v-col> -->
                <v-col
                  v-for="(value, key) in report.dgaReport"
                  :key="key"
                  cols="8"
                >
                  <v-text-field
                    v-if="
                      typeof value === 'string' || typeof value === 'number'
                    "
                    :label="key"
                    v-model="report.dgaReport[key]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style>
  .title-actions {
    position: sticky;
    top: 0;
    background-color: white; /* Asegúrate de que el fondo del header sea consistente */
    z-index: 1;
  }
</style>
