<script setup>
  import { watch, ref } from 'vue'
  import { useReportsStore } from '../store'
  import { useThemeStore } from '../../../stores/theme'
  import { storeToRefs } from 'pinia'
  import {
    showString,
    showFloat,
    showBool,
    showDate,
    showUTM,
    showDatum,
    showM3s,
    showCumple,
    showNotes,
  } from '../utils'

  import { setTextClasificacion } from '@/config/utils'

  // import { dataValueMapping } from '../utils'
  import ReportDetailsChart from './ReportDetailsChart.vue'

  // Store
  const reportsStore = useReportsStore()
  const { currentReport } = storeToRefs(reportsStore)
  const themeStore = useThemeStore()
  // Data
  let highlightedData = ref([])
  let bodyData = ref([])
  let measurementReportsData = ref([])
  let notes = ref([])
  let graphData = ref([])
  let graphCaudalEcologico = ref(0)
  let detailsData = ref([])
  const panel = ref(0)

  // Methods
  const toggleReportDetails = () => {
    reportsStore.toggleReportDetails()
  }

  // Watch
  watch(
    currentReport,
    report => {
      let currentReport = report.dgaReport
      // eslint-disable-next-line
      console.log(currentReport)
      let allMeasurements = report.allReports.map(report => report.dgaReport)

      highlightedData.value = [
        {
          title: 'Código de Expediente',
          subtitle: showString(currentReport['codigoExpediente']),
        },
        {
          title: 'Nombre de Solicitante',
          subtitle: showString(currentReport['nombreSolicitante']),
        },
        {
          title: 'Región',
          subtitle: showString(currentReport['region']),
        },
        {
          title: 'Provincia',
          subtitle: showString(currentReport['provincia']),
        },
        {
          title: 'Comuna',
          subtitle: showString(currentReport['comuna']),
        },
        {
          title: 'Caudal Ecológico',
          subtitle: showFloat(currentReport['caudalEcologico'], 3) + ' m³/s',
        },
        // {
        //   title: '¿Cumplimiento?',
        //   subtitle: showBool(currentReport['tieneCumplimiento']),
        // },
        // {
        //   title: '¿Caudal Ambiental?',
        //   subtitle: showBool(currentReport['tieneCaudalAmbiental']),
        // },
        // {
        //   title: '¿Cumplimiento Caudal Ambiental?',
        //   subtitle: currentReport['cumplimientoCaudalAmbiental'],
        // },
      ]

      bodyData.value = [
        {
          title: 'Tipo Derecho',
          subtitle: showString(currentReport['tipoDerecho']),
        },
        {
          title: 'Naturaleza del Agua',
          subtitle: showString(currentReport['naturalezaDelAgua']),
        },
        {
          title: 'Clasificación Fuente',
          subtitle: showString(currentReport['clasificacionFuente']),
        },
        {
          title: 'Uso del Agua',
          subtitle: showString(currentReport['usoAgua']),
        },
        {
          title: 'Cuenca',
          subtitle: showString(currentReport['cuenca']),
        },
        {
          title: 'SubCuenca',
          subtitle: showString(currentReport['subCuenca']),
        },
        {
          title: 'SubSubCuenca',
          subtitle: showString(currentReport['subSubCuenca']),
        },
        {
          title: 'Fuente',
          subtitle: showString(currentReport['fuenteAgua']),
        },
        {
          title: 'UTM Captación',
          subtitle: showUTM(
            currentReport['husoCaptacion'],
            currentReport['utmEsteCaptacion'],
            currentReport['utmNorteCaptacion'],
          ),
        },
        {
          title: 'Datum Captación',
          subtitle: showDatum(currentReport['datumCaptacion']),
        },
        {
          title: 'UTM Captación Conversión',
          subtitle: showUTM(
            currentReport['husoCaptacionConversion'],
            currentReport['utmEsteCaptacionConversion'],
            currentReport['utmNorteCaptacionConversion'],
          ),
        },
        {
          title: 'Datum Captación Conversión',
          subtitle: showDatum(currentReport['datumCaptacionConversion']),
        },
        {
          title: 'UTM Captación en Terreno',
          subtitle: showUTM(
            currentReport['husoCaptacionTerreno'],
            currentReport['utmEsteCaptacionTerreno'],
            currentReport['utmNorteCaptacionTerreno'],
          ),
        },
      ]

      measurementReportsData.value = allMeasurements.map(currentReport => [
        {
          title: 'Fecha de Inspección',
          subtitle: showDate(currentReport['fechaInspeccion']),
        },
        {
          title: '¿Ejerce Derecho?',
          subtitle: showBool(currentReport['ejerceDerecho']),
        },
        {
          title: '¿Existe Obra?',
          subtitle: showBool(currentReport['existeObra']),
        },
        {
          title: '¿RCA?',
          subtitle: showBool(currentReport['tieneRCA']),
        },
        {
          title: 'Tipo Captación',
          subtitle: showString(currentReport['tipoCaptacion']),
        },
        {
          title: 'Uso Corroborado en Terreno',
          subtitle: showString(currentReport['usoCorroboradoTerreno']),
        },
        {
          title: 'Ejercicio del Derecho',
          subtitle: showString(currentReport['ejercicioDerecho']),
        },
        {
          title: '¿Tiene Renuncia?',
          subtitle: showBool(currentReport['tieneRenuncia']),
        },
        {
          title: '¿Tiene Transacción?',
          subtitle: showBool(currentReport['tieneTransacción']),
        },
        {
          title: 'UTM Aforo',
          subtitle: showUTM(
            currentReport['husoAforo'],
            currentReport['utmEsteAforo'],
            currentReport['utmNorteAforo'],
          ),
        },
        {
          title: 'Caudal Medido',
          subtitle: showM3s(showFloat(currentReport['caudalMedido'], 3)),
        },
        {
          title: '¿Cumple?',
          subtitle: showCumple(currentReport['clasificacionIndividual']),
        },
        {
          title: 'Observaciones',
          subtitle: showNotes(currentReport['observaciones']),
        },
      ])

      graphData.value = allMeasurements.map(currentReport => {
        return {
          fecha: currentReport['fechaInspeccion'],
          caudal: currentReport['caudalMedido'],
        }
      })

      notes.value = allMeasurements.map(currentReport => {
        return {
          fecha: showDate(currentReport['fechaInspeccion']),
          observaciones: showNotes(currentReport['observaciones']),
        }
      })

      graphCaudalEcologico.value = parseFloat(currentReport['caudalEcologico'])

      detailsData.value = [
        {
          title: 'Número Medición',
          subtitle: showString(currentReport['numeroMedicion']),
        },
        {
          title: 'Número Solicitud',
          subtitle: showString(currentReport['numeroSolicitud']),
        },
        {
          title: 'Unidad de Solución/Oficio/C.B.R.',
          subtitle: showString(currentReport['unidadResolucion']),
        },
        {
          title: 'Fecha de Resolución/Envío al Juez/Inscripción C.B.R.',
          subtitle: showDate(currentReport['fechaResolucion']),
        },
        {
          title: 'Fecha Toma Razón',
          subtitle: showDate(currentReport['fechaTomaRazon']),
        },
        {
          title: 'Artículo Transitorio',
          subtitle: showString(currentReport['articuloTransitorio']),
        },
        {
          title: 'Enero',
          subtitle: showM3s(showFloat(currentReport['medicionEnero'], 3)),
        },
        {
          title: 'Febrero',
          subtitle: showM3s(showFloat(currentReport['medicionFebrero'], 3)),
        },
        {
          title: 'Marzo',
          subtitle: showM3s(showFloat(currentReport['medicionMarzo'], 3)),
        },
        {
          title: 'Abril',
          subtitle: showM3s(showFloat(currentReport['medicionAbril'], 3)),
        },
        {
          title: 'Mayo',
          subtitle: showM3s(showFloat(currentReport['medicionMayo'], 3)),
        },
        {
          title: 'Junio',
          subtitle: showM3s(showFloat(currentReport['medicionJunio'], 3)),
        },
        {
          title: 'Julio',
          subtitle: showM3s(showFloat(currentReport['medicionJulio'], 3)),
        },
        {
          title: 'Agosto',
          subtitle: showM3s(showFloat(currentReport['medicionAgosto'], 3)),
        },
        {
          title: 'Septiembre',
          subtitle: showM3s(showFloat(currentReport['medicionSeptiembre'], 3)),
        },
        {
          title: 'Octubre',
          subtitle: showM3s(showFloat(currentReport['medicionOctubre'], 3)),
        },
        {
          title: 'Noviembre',
          subtitle: showM3s(showFloat(currentReport['medicionNoviembre'], 3)),
        },
        {
          title: 'Diciembre',
          subtitle: showM3s(showFloat(currentReport['medicionDiciembre'], 3)),
        },
        {
          title: 'Caudal Anual Promedio',
          subtitle: showM3s(
            showFloat(currentReport['caudalAnualPromedioM3S'], 3),
          ),
        },
        {
          title: '¿Caudal Promedio Anual?',
          subtitle: showBool(currentReport['tieneCaudalPromedioAnual']),
        },
        {
          title: '¿Caudal Ecológico Promedio?',
          subtitle: showBool(currentReport['tieneCaudalEcologicoPromedio']),
        },
        {
          title: 'C.B.R.',
          subtitle: showString(currentReport['cbr']),
        },
        {
          title: 'Fojas',
          subtitle: showString(currentReport['fojas']),
        },
        {
          title: 'Número C.B.R.',
          subtitle: showString(currentReport['numeroCBR']),
        },
        {
          title: 'Año',
          subtitle: showString(currentReport['año']),
        },
      ]
    },
    { immediate: true },
  )
</script>

<template>
  <v-navigation-drawer
    v-if="currentReport"
    persistent
    width="600"
    location="left"
  >
    <div
      class="card-padding text-center white elevation shadow-green border-radius-lg mx-4 mt-3"
    >
      <v-img
        contain
        height="40px"
        src="/img/icons/aqfletter.svg"
      />
    </div>

    <v-divider class="mt-3"></v-divider>

    <v-card
      class="mx-4 pa-4"
      elevation="0"
    >
      <v-card-item class="px-4">
        <template v-slot:title>
          <v-row
            no-gutters
            justify-end
          >
            <v-col cols="10">
              <h2>
                {{ showString(currentReport.dgaReport.codigoExpediente) }}
              </h2>
            </v-col>
            <v-col>
              <v-icon
                class="ma-3"
                @click="toggleReportDetails"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
        </template>
        <template v-slot:subtitle>
          <div class="mb-2">
            <div>
              <p class="header-text">
                <v-icon
                  class="me-1 pb-1"
                  icon="mdi-account"
                  size="18"
                />
                {{ showString(currentReport.dgaReport.nombreSolicitante) }}
              </p>
            </div>
            <div>
              <p class="header-text">
                <v-icon
                  class="me-1 pb-1"
                  icon="mdi-map-marker"
                  size="18"
                />
                {{ showString(currentReport.dgaReport.region) }} /
                {{ showString(currentReport.dgaReport.provincia) }} /
                {{ showString(currentReport.dgaReport.comuna) }}
              </p>
            </div>
            <div>
              <p class="header-text">
                <v-icon
                  class="me-1 pb-1"
                  icon="mdi-waves"
                  size="18"
                />
                {{ showString(currentReport.dgaReport.subSubCuenca) }}
              </p>
            </div>
          </div>
        </template>
      </v-card-item>

      <v-card-text class="py-3">
        <v-row
          align="center"
          no-gutters
        >
          <v-col
            class="text-left"
            cols="3"
          >
            <v-img
              width="100px"
              :src="`/img/icons/${currentReport.dgaReport.letraClasificacion}.svg`"
            />
          </v-col>

          <v-col
            class="rank-info"
            cols="7"
          >
            <v-row>
              <p>
                <strong>Criterio:</strong>
                {{
                  setTextClasificacion(
                    currentReport.dgaReport.letraClasificacion,
                  )
                }}
              </p>
            </v-row>
            <v-row>
              <p>
                <strong>Caudal Ecológico:</strong>
                {{
                  showM3s(showFloat(currentReport.dgaReport.caudalEcologico, 3))
                }}
              </p>
            </v-row>
            <v-row>
              <p>
                <strong>Última Medición:</strong>
                {{
                  showM3s(showFloat(currentReport.dgaReport.caudalMedido, 3))
                }}
                ({{ showDate(currentReport.dgaReport.fechaInspeccion) }})
              </p>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <ReportDetailsChart
        v-if="currentReport.dgaReport.letraClasificacion != 'N'"
        :seriesData="graphData"
        :caudalEcologico="graphCaudalEcologico"
      />

      <v-alert
        title="Observaciones de terreno"
        type="info"
        variant="tonal"
      >
        <v-list-item
          v-for="(item, index) in notes"
          :key="`item-${index}`"
        >
          <v-list-item>
            <div class="d-flex justify-space-between">
              <v-list-item-title>{{ item.fecha }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.observaciones
              }}</v-list-item-subtitle>
            </div>
          </v-list-item>
          <v-divider />
        </v-list-item>
      </v-alert>

      <v-expansion-panels
        v-model="panel"
        class="my-4 mr-10"
        :elevation="themeStore.currentTheme == 'myCustomDarkTheme' ? 6 : 2"
        variant="popout"
      >
        <v-expansion-panel key="details">
          <v-expansion-panel-title
            >Datos de la solicitud</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <v-list-item
              v-for="(item, index) in highlightedData"
              :key="`item-${index}`"
            >
              <v-divider />
              <v-list-item>
                <div class="d-flex justify-space-between">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.subtitle
                  }}</v-list-item-subtitle>
                </div>
              </v-list-item>
            </v-list-item>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel key="body">
          <v-expansion-panel-title>Información general</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list-item
              v-for="(item, index) in bodyData"
              :key="`item-${index}`"
            >
              <v-divider />
              <v-list-item>
                <div class="d-flex justify-space-between">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.subtitle
                  }}</v-list-item-subtitle>
                </div>
              </v-list-item>
            </v-list-item>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel key="body">
          <v-expansion-panel-title
            >Mediciones en terreno</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <v-list-item
              v-for="(currentReport, index) in measurementReportsData"
              :key="`item-${index}`"
            >
              <v-divider />
              <v-list-item
                v-for="(item, indexReport) in currentReport"
                :key="`item-${indexReport}`"
              >
                <v-list-item>
                  <div class="d-flex justify-space-between">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.subtitle
                    }}</v-list-item-subtitle>
                  </div>
                </v-list-item>
              </v-list-item>
            </v-list-item>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel key="othersDetails">
          <v-expansion-panel-title
            >Información adicional</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <v-list-item
              v-for="(item, index) in detailsData"
              :key="`item-${index}`"
            >
              <v-divider />
              <v-list-item>
                <div class="d-flex justify-space-between">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.subtitle
                  }}</v-list-item-subtitle>
                </div>
              </v-list-item>
            </v-list-item>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-navigation-drawer>
</template>

<style scoped>
  .header-text {
    font-size: 14px;
    color: #2f2f2f;
  }
  .rank-info {
    font-size: 14px;
    color: #6c757d;
  }
</style>
