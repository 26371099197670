<script setup>
  import * as XLSX from 'xlsx'

  const props = defineProps({
    filteredReports: {
      type: Array,
      required: true,
    },
  })

  const generateXLSX = locations => {
    // eslint-disable-next-line
    console.log(locations)

    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet([])

    const actualHeader = [
      'N°',
      'Código de Expediente',
      'N°  Solicitud',
      'Región',
      'Provincia',
      'Comuna',
      'Nombre Solicitante',
      'Unidad de Resolución/ Oficio/ C.B.R.',
      'Fecha de Resolución/ Envío al Juez/ Inscripción C.B.R.',
      'Fecha Toma Razón',
      'Artículo Transitorio',
      'Tipo Derecho',
      'Naturaleza del Agua',
      'Clasificación Fuente',
      'Uso del Agua',
      'Cuenca',
      'SubCuenca',
      'SubSubCuenca',
      'Fuente',
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
      'Ejercicio del Derecho',
      'Caudal Anual Promedio (m3/s)',
      '¿Caudal Promedio Anual?',
      'Caudal Ecológico (m3/s)',
      '¿Caudal Ecológico Promedio?',
      'UTM Norte Captación (m)',
      'UTM Este Captación (m)',
      'Huso Captación',
      'Datum Captación',
      'UTM Norte Captación conversión (m)',
      'UTM Este Captación conversión (m)',
      'Huso Captación conversión',
      'Datum Captación conversión',
      'C.B.R.',
      'Fojas',
      'N° CBR',
      'Año',
      'Tiene Renuncia',
      'Tiene Transacción',
      'Fecha de inspección',
      'UTM Norte Captación terreno (m)',
      'UTM Este Captación terreno (m)',
      'Huso Captación terreno',
      'UTM Norte Aforo (m)',
      'UTM Este aforo (m)',
      'Huso Aforo',
      '¿Ejerce derecho?',
      '¿Existe Obra?',
      'Tipo de Captacion',
      'Uso corroborado en terreno',
      'Caudal Medido',
      '¿Cumplimiento?',
      '¿RCA?',
      'Caudal Ambiental',
      '¿Cumplimiento caudal ambiental?',
      'Observaciones',
    ]

    const customHeader = [
      'numeroMedicion',
      'codigoExpediente',
      'numeroSolicitud',
      'region',
      'provincia',
      'comuna',
      'nombreSolicitante',
      'unidadResolucion',
      'fechaResolucion',
      'fechaTomaRazon',
      'articuloTransitorio',
      'tipoDerecho',
      'naturalezaDelAgua',
      'clasificacionFuente',
      'usoAgua',
      'cuenca',
      'subCuenca',
      'subSubCuenca',
      'fuenteAgua',
      'medicionEnero',
      'medicionFebrero',
      'medicionMarzo',
      'medicionAbril',
      'medicionMayo',
      'medicionJunio',
      'medicionJulio',
      'medicionAgosto',
      'medicionSeptiembre',
      'medicionOctubre',
      'medicionNoviembre',
      'medicionDiciembre',
      'ejercicioDerecho',
      'caudalAnualPromedioM3S',
      'tieneCaudalPromedioAnual',
      'caudalEcologico',
      'tieneCaudalEcologicoPromedio',
      'utmNorteCaptacion',
      'utmEsteCaptacion',
      'husoCaptacion',
      'datumCaptacion',
      'utmNorteCaptacionConversion',
      'utmEsteCaptacionConversion',
      'husoCaptacionConversion',
      'datumCaptacionConversion',
      'cbr',
      'fojas',
      'numeroCBR',
      'año',
      'tieneRenuncia',
      'tieneTransacción',
      'fechaInspeccion',
      'utmNorteCaptacionTerreno',
      'utmEsteCaptacionTerreno',
      'husoCaptacionTerreno',
      'utmNorteAforo',
      'utmEsteAforo',
      'husoAforo',
      'ejerceDerecho',
      'existeObra',
      'tipoCaptacion',
      'usoCorroboradoTerreno',
      'caudalMedido',
      'tieneCumplimiento',
      'tieneRCA',
      'tieneCaudalAmbiental',
      'cumplimientoCaudalAmbiental',
      'observaciones',
    ]

    const dateColumns = ['fechaResolucion', 'fechaTomaRazon', 'fechaInspeccion']

    const booleanColumns = [
      'tieneRenuncia',
      'tieneTransacción',
      'ejerceDerecho',
      'existeObra',
      'tieneCumplimiento',
      'tieneRCA',
      'tieneCaudalAmbiental',
      'tieneCaudalPromedioAnual',
      'tieneCaudalEcologicoPromedio',
    ]

    // const headers = Object.keys(reports[0].dgaReport)
    XLSX.utils.sheet_add_aoa(ws, [actualHeader], { origin: 'A1' })

    // Necesitamos generar un offset para que se agreguen los reportes en la fila correcta
    let reportRowOffset = 0

    locations.forEach((location, index) => {
      location.allReports.forEach(report => {
        const row = customHeader.map(header => {
          if (dateColumns.includes(header)) {
            if (!report.dgaReport[header]) return ''
            return new Date(
              report.dgaReport[header].seconds * 1000,
            ).toLocaleDateString('es-CL')
          } else if (booleanColumns.includes(header)) {
            if (report.dgaReport[header] === undefined) return ''
            return report.dgaReport[header] ? 'Sí' : 'No'
          }
          return report.dgaReport[header]
        })

        XLSX.utils.sheet_add_aoa(ws, [row], {
          origin: `A${index + reportRowOffset + 2}`,
        })

        reportRowOffset++
      })

      reportRowOffset--
    })
    XLSX.utils.book_append_sheet(wb, ws, 'Reportes')
    XLSX.writeFile(wb, 'reportes.xlsx')
  }
</script>

<template>
  <div>
    <v-btn
      v-tooltip:start="'Descargar reportes en formato Excel'"
      class="mt-1 mx-3"
      size="small"
      icon="mdi-microsoft-excel"
      @click="generateXLSX(props.filteredReports)"
    />
  </div>
</template>

<style scoped>
  .custom-small-btn {
    font-size: 0.75rem;
    padding: 2px 4px;
    min-width: auto;
  }
</style>

<!-- {
  "arms": "",
  "status": "",
  "measurements": [],
  "number": "",
  "startTimeVisit": "",
  "internalNotes": "",
  "duration": "",
  "dgaReport": {
      "tieneRCA": false,
      "utmEsteCaptacionConversion": 685976,
      "tieneTransacción": false,
      "comuna": "Cholchol",
      "medicionAgosto": 4,
      "cumplimientoCaudalAmbiental": "N/A",
      "medicionDiciembre": 4,
      "tipoDerecho": "Consuntivo",
      "medicionFebrero": 4,
      "region": "De La Araucania",
      "fechaInspeccion": {
          "seconds": 1506913200,
          "nanoseconds": 0
      },
      "usoCorroboradoTerreno": "Hidroelectrico",
      "subSubCuenca": "Rio Chol Chol entre Rio Quillen y Rio Renaco",
      "medicionEnero": 4,
      "medicionNoviembre": 4,
      "tieneCaudalEcologicoPromedio": true,
      "medicionJulio": 4,
      "medicionJunio": 4,
      "mapaLatitud": -38.57371554970423,
      "cuenca": "Rio Imperial",
      "unidadResolucion": "DGA Temuco",
      "utmEsteCaptacionTerreno": 286883,
      "fojas": "104",
      "utmEsteAforo": 286682,
      "tipoCaptacion": "Mecanica",
      "husoCaptacionTerreno": 20,
      "subCuenca": "R.  Chol Chol",
      "datumCaptacion": 1984,
      "cbr": "C.B.R. Nueva Imperial",
      "fechaTomaRazon": {
          "seconds": 1506913200,
          "nanoseconds": 0
      },
      "mapaLongitud": -66.8651280860592,
      "caudalAnualPromedio": 4,
      "tieneRenuncia": false,
      "caudalEcologico": 16067.5,
      "existeObra": true,
      "nombreSolicitante": "LORENZO REUMAY LONCON Y OTROS",
      "tieneCumplimiento": true,
      "caudalMedido": 15.19,
      "caudalAnualPromedioM3S": 0.004,
      "datumCaptacionConversion": 1983,
      "medicionMarzo": 4,
      "tieneCaudalAmbiental": false,
      "medicionAbril": 4,
      "ejerceDerecho": true,
      "clasificacionFuente": "Rio/Estero",
      "usoAgua": "Energia Hidroeléctrica",
      "articuloTransitorio": "0",
      "husoCaptacion": 18,
      "año": "2022",
      "utmNorteCaptacionTerreno": 6075447,
      "numeroResolucion": 104,
      "utmEsteCaptacion": 685976,
      "codigoExpediente": "VT-0902-1144",
      "numeroSolicitud": 1,
      "medicionMayo": 4,
      "tieneCaudalPromedioAnual": true,
      "naturalezaDelAgua": "Superficial",
      "unidadCaudal": "Lt/s",
      "medicionSeptiembre": 4,
      "utmNorteCaptacionConversion": 5728366,
      "medicionOctubre": 4,
      "fechaResolucion": {
          "seconds": 1644980400,
          "nanoseconds": 0
      },
      "ejercicioDerecho": "Permanente y Continuo",
      "provincia": "Cautin",
      "numeroCBR": 80,
      "utmNorteCaptacion": 5728366,
      "utmNorteAforo": 6075434,
      "husoCaptacionConversion": 19,
      "fuenteAgua": "Rio Chol Chol",
      "numeroMedicion": 168,
      "porcentajeCumplimiento": "94.54",
      "clasificacionIndividual": "No Cumple",
      "letraClasificacion": "E"
  },
  "startingPoint": "",
  "endTimeVisit": "",
  "id": "384834ef166c4edbb6a0f61f73654fd1",
  "updatedDate": "",
  "locationId": "",
  "measurementMethodName": "",
  "createdDate": "2024-07-05T14:52:25-04:00",
  "date": "",
  "equipName": "",
  "adminNotes": "",
  "location": "",
  "multimedia": {
      "images": [],
      "videos": []
  },
  "lat": 0,
  "measurementObs": "",
  "quoteId": "",
  "hRuler": {
      "cm": 0,
      "selectUnit": "",
      "m": 0
  },
  "aggregatedData": {
      "sectionArea": {
          "m2": 0,
          "selectUnit": "",
          "cm2": 0
      },
      "totalFlow": {
          "selectUnit": "",
          "m3s": 0,
          "mm3s": 0,
          "lts": 0
      },
      "avgVel": {
          "cms": 0,
          "selectUnit": "",
          "ms": 0
      },
      "sectionWidth": {
          "selectUnit": "",
          "m": 0,
          "cm": 0
      }
  },
  "riverbedId": "",
  "code": "",
  "lng": 0,
  "measurementPolicyName": "",
  "teams": [],
  "allReports": [
      {
          "arms": "",
          "status": "",
          "measurements": [],
          "number": "",
          "startTimeVisit": "",
          "internalNotes": "",
          "duration": "",
          "dgaReport": {
              "tieneRCA": false,
              "utmEsteCaptacionConversion": 685976,
              "tieneTransacción": false,
              "comuna": "Cholchol",
              "medicionAgosto": 4,
              "cumplimientoCaudalAmbiental": "N/A",
              "medicionDiciembre": 4,
              "tipoDerecho": "Consuntivo",
              "medicionFebrero": 4,
              "region": "De La Araucania",
              "fechaInspeccion": {
                  "seconds": 1506913200,
                  "nanoseconds": 0
              },
              "usoCorroboradoTerreno": "Hidroelectrico",
              "subSubCuenca": "Rio Chol Chol entre Rio Quillen y Rio Renaco",
              "medicionEnero": 4,
              "medicionNoviembre": 4,
              "tieneCaudalEcologicoPromedio": true,
              "medicionJulio": 4,
              "medicionJunio": 4,
              "mapaLatitud": -38.57371554970423,
              "cuenca": "Rio Imperial",
              "unidadResolucion": "DGA Temuco",
              "utmEsteCaptacionTerreno": 286883,
              "fojas": "104",
              "utmEsteAforo": 286682,
              "tipoCaptacion": "Mecanica",
              "husoCaptacionTerreno": 20,
              "subCuenca": "R.  Chol Chol",
              "datumCaptacion": 1984,
              "cbr": "C.B.R. Nueva Imperial",
              "fechaTomaRazon": {
                  "seconds": 1506913200,
                  "nanoseconds": 0
              },
              "mapaLongitud": -66.8651280860592,
              "caudalAnualPromedio": 4,
              "tieneRenuncia": false,
              "caudalEcologico": 16067.5,
              "existeObra": true,
              "nombreSolicitante": "LORENZO REUMAY LONCON Y OTROS",
              "tieneCumplimiento": true,
              "caudalMedido": 15.19,
              "caudalAnualPromedioM3S": 0.004,
              "datumCaptacionConversion": 1983,
              "medicionMarzo": 4,
              "tieneCaudalAmbiental": false,
              "medicionAbril": 4,
              "ejerceDerecho": true,
              "clasificacionFuente": "Rio/Estero",
              "usoAgua": "Energia Hidroeléctrica",
              "articuloTransitorio": "0",
              "husoCaptacion": 18,
              "año": "2022",
              "utmNorteCaptacionTerreno": 6075447,
              "numeroResolucion": 104,
              "utmEsteCaptacion": 685976,
              "codigoExpediente": "VT-0902-1144",
              "numeroSolicitud": 1,
              "medicionMayo": 4,
              "tieneCaudalPromedioAnual": true,
              "naturalezaDelAgua": "Superficial",
              "unidadCaudal": "Lt/s",
              "medicionSeptiembre": 4,
              "utmNorteCaptacionConversion": 5728366,
              "medicionOctubre": 4,
              "fechaResolucion": {
                  "seconds": 1644980400,
                  "nanoseconds": 0
              },
              "ejercicioDerecho": "Permanente y Continuo",
              "provincia": "Cautin",
              "numeroCBR": 80,
              "utmNorteCaptacion": 5728366,
              "utmNorteAforo": 6075434,
              "husoCaptacionConversion": 19,
              "fuenteAgua": "Rio Chol Chol",
              "numeroMedicion": 168,
              "porcentajeCumplimiento": "94.54",
              "clasificacionIndividual": "No Cumple",
              "letraClasificacion": "E"
          },
          "startingPoint": "",
          "endTimeVisit": "",
          "id": "384834ef166c4edbb6a0f61f73654fd1",
          "updatedDate": "",
          "locationId": "",
          "measurementMethodName": "",
          "createdDate": "2024-07-05T14:52:25-04:00",
          "date": "",
          "equipName": "",
          "adminNotes": "",
          "location": "",
          "multimedia": {
              "images": [],
              "videos": []
          },
          "lat": 0,
          "measurementObs": "",
          "quoteId": "",
          "hRuler": {
              "cm": 0,
              "selectUnit": "",
              "m": 0
          },
          "aggregatedData": {
              "sectionArea": {
                  "m2": 0,
                  "selectUnit": "",
                  "cm2": 0
              },
              "totalFlow": {
                  "selectUnit": "",
                  "m3s": 0,
                  "mm3s": 0,
                  "lts": 0
              },
              "avgVel": {
                  "cms": 0,
                  "selectUnit": "",
                  "ms": 0
              },
              "sectionWidth": {
                  "selectUnit": "",
                  "m": 0,
                  "cm": 0
              }
          },
          "riverbedId": "",
          "code": "",
          "lng": 0,
          "measurementPolicyName": "",
          "teams": []
      }
  ]
} -->
