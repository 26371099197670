<script setup>
  import { computed, onMounted } from 'vue'

  import { useThemeStore } from '@/stores/theme'
  import { watch } from 'vue'
  const themeStore = useThemeStore()
  const modeTheme = computed(() => themeStore.theme)

  import { useTheme } from 'vuetify'

  const theme = useTheme()

  watch(
    () => themeStore.currentTheme,
    newTheme => {
      theme.global.name.value = newTheme
    },
    { immediate: true },
  )

  onMounted(() => {
    document.title = 'AquaFlow // DGA Dashboard'
  })
</script>
<template>
  <v-app class="full">
    <v-theme-provider :theme="modeTheme">
      <v-main>
        <router-view />
      </v-main>
    </v-theme-provider>
  </v-app>
</template>

<style>
  .full {
    height: 100dvh;
    width: 100vw;
    overflow: hidden;
  }
</style>
