import { db } from '@/config/firebase'
// import { collection, getDocs, setDoc, doc } from 'firebase/firestore'
import {
  setDoc,
  doc,
  getDocs,
  collection,
  updateDoc,
  where,
  query,
} from 'firebase/firestore'
import * as XLSX from 'xlsx'
import moment from 'moment-timezone'
import { generateUUID } from '@/config/utils'
const utm = require('utm')
export default {
  animateZoom(targetZoom, duration) {
    const startZoom = this.dynamicZoom
    const zoomDifference = targetZoom - startZoom
    const startTime = performance.now()

    const step = currentTime => {
      const elapsedTime = currentTime - startTime
      const progress = Math.min(elapsedTime / duration, 1)
      const currentZoom = startZoom + zoomDifference * progress
      this.dynamicZoom = currentZoom

      if (progress < 1) {
        requestAnimationFrame(step)
      } else {
        // Asegura que el zoom final sea el deseado
        this.dynamicZoom = targetZoom
      }
    }

    requestAnimationFrame(step)
  },
  setCurrentReport(report) {
    this.currentReport = report

    setTimeout(() => {
      if (report.dgaReport.mapaLatitud !== 0) {
        const previousCenter = { ...this.center }

        this.center.lat = report.dgaReport.mapaLatitud
        this.center.lng = report.dgaReport.mapaLongitud

        if (
          previousCenter.lat === this.center.lat &&
          previousCenter.lng === this.center.lng
        ) {
          this.center.lat += 0.000001
          this.center.lng += 0.000001

          setTimeout(() => {
            this.center.lat = report.dgaReport.mapaLatitud
            this.center.lng = report.dgaReport.mapaLongitud
          }, 50)
        }

        setTimeout(() => {
          this.dynamicZoom = 18
        }, 100)
      }
    }, 200)
    this.loadPositionAforo(report)
  },
  setDynamicZoom(zoom) {
    this.dynamicZoom = zoom
  },

  addWarning(warningMessage) {
    this.warningQueue.push(warningMessage)
  },

  addError(errorMessage) {
    this.errorQueue.push(errorMessage)
  },

  clearErrors() {
    this.errorQueue = []
  },

  clearWarning() {
    this.warningQueue = []
  },

  toggleReportsList() {
    this.isReportsListVisible = !this.isReportsListVisible
  },

  toggleReportDetails() {
    this.isReportsSyncVisible = false
    this.isReportDetailsVisible = !this.isReportDetailsVisible
    // if (this.currentReport) {
    //   this.curretReport = null
    //   //this.deleteTemporaryMarker()
    // }
  },
  togleChangeMapType(value) {
    if (value) {
      this.mapType = 'satellite'
    } else {
      this.mapType = 'roadmap'
    }
  },
  toggleReportsSync() {
    this.isReportDetailsVisible = false
    this.isReportsSyncVisible = !this.isReportsSyncVisible
  },

  showReportDetails() {
    this.isReportsSyncVisible = false
    this.isReportDetailsVisible = true
  },

  setSearch(value) {
    this.search = value
  },

  async overwriteReports() {
    let timeNowChilean = moment.tz('America/Santiago')
    const timestamp = timeNowChilean.format()
    for (let importReport of this.importedReports) {
      let report = {
        id: '',
        status: '',
        date: '',
        duration: '',
        arms: '',
        code: '',
        number: '',
        startingPoint: '',
        startTimeVisit: '',
        endTimeVisit: '',
        location: '',
        aggregatedData: {
          avgVel: {
            ms: 0,
            cms: 0,
            selectUnit: '',
          },
          totalFlow: {
            m3s: 0,
            mm3s: 0,
            lts: 0,
            selectUnit: '',
          },
          sectionArea: {
            m2: 0,
            cm2: 0,
            selectUnit: '',
          },
          sectionWidth: {
            m: 0,
            cm: 0,
            selectUnit: '',
          },
        },
        hRuler: {
          m: 0,
          cm: 0,
          selectUnit: '',
        },
        lat: 0,
        lng: 0,
        riverbedId: '',
        quoteId: '',
        locationId: '',
        measurementMethodName: '',
        measurementPolicyName: '',
        equipName: '',
        teams: [],
        measurementObs: '',
        adminNotes: '',
        internalNotes: '',
        measurements: [],
        multimedia: {
          videos: [],
          images: [],
        },
        createdDate: '',
        updatedDate: '',
        dgaReport: {},
      }
      report.id = generateUUID()
      report.createdDate = timestamp
      report.dgaReport = importReport
      await setDoc(doc(db, 'reports', report.id), report)
    }

    this.reports = this.importedReports
  },

  _processJsonData(jsonData) {
    const booleanColumns = [
      'tieneRenuncia',
      'tieneTransacción',
      'ejerceDerecho',
      'existeObra',
      'tieneCumplimiento',
      'tieneRCA',
      'tieneCaudalAmbiental',
      'tieneCaudalPromedioAnual',
      'tieneCaudalEcologicoPromedio',
    ]

    const medicionesColumns = [
      'medicionEnero',
      'medicionFebrero',
      'medicionMarzo',
      'medicionAbril',
      'medicionMayo',
      'medicionJunio',
      'medicionJulio',
      'medicionAgosto',
      'medicionSeptiembre',
      'medicionOctubre',
      'medicionNoviembre',
      'medicionDiciembre',
    ]

    const floatColumns = [
      'caudalAnualPromedio',
      'caudalAnualPromedioM3S',
      'caudalEcologico',
      'caudalMedido',
      'utmEsteAforo',
      'utmEsteCaptacion',
      'utmNorteAforo',
      'utmNorteCaptacion',
      'utmEsteCaptacionTerreno',
      'utmNorteCaptacionTerreno',
    ]

    const intColumns = [
      'datumCaptacion',
      'datumCaptacionConversion',
      'husoCaptacion',
      'husoCaptacionTerreno',
      'husoAforo',
      'numeroCBR',
      'numeroMedicion',
      'numeroSolicitud',
    ]

    const dateColumns = ['fechaResolucion', 'fechaTomaRazon', 'fechaInspeccion']

    const stringColumns = [
      'codigoExpediente',
      'region',
      'provincia',
      'comuna',
      'nombreSolicitante',
      'unidadResolucion',
      'articuloTransitorio',
      'tipoDerecho',
      'naturalezaDelAgua',
      'clasificacionFuente',
      'usoAgua',
      'cuenca',
      'subCuenca',
      'subSubCuenca',
      'fuenteAgua',
      'ejercicioDerecho',
      'unidadCaudal',
      'cbr',
      'fojas',
      'año',
      'tipoCaptacion',
      'usoCorroboradoTerreno',
      'cumplimientoCaudalAmbiental',
      'observaciones',
    ]

    // Limpiar error queue
    this.clearErrors()

    // Remove the header first
    jsonData.shift()

    jsonData.forEach((report, index) => {
      // Crear un índice para tener una referencia de la posición del reporte
      const reportIndex = index + 3

      const logRecord = report['codigoExpediente']
        ? reportIndex + ' ' + report['codigoExpediente']
        : reportIndex

      booleanColumns.forEach(column => {
        try {
          if (!Object.prototype.hasOwnProperty.call(report, column)) {
            this.addWarning(`#${logRecord} | ${column} | No encontrada.`)
            report[column] = null
            return
          }

          if (report[column].toString().trim() === '') {
            this.addWarning(`#${logRecord} | ${column} | No encontrada.`)
            report[column] = null
            return
          }

          if (
            report[column].toString().trim().toUpperCase() === 'SI' ||
            report[column].toString().trim().toUpperCase() === 'SÍ'
          ) {
            report[column] = true
            return
          } else {
            report[column] = false
            return
          }
          // this.addError(
          //   `#${logRecord} | ${column} | No procesado. Use SI o NO. | Valor encontrado: ` +
          //     report[column],
          // )
        } catch (error) {
          this.addError(
            `#${logRecord} | ${column} | Error no capturado: ${error}`,
          )
        }
      })

      floatColumns.forEach(column => {
        try {
          if (!Object.prototype.hasOwnProperty.call(report, column)) {
            this.addWarning(`#${logRecord} | ${column} | No encontrada.`)
            report[column] = null
            return
          }

          if (typeof report[column] === 'number') return

          if (
            typeof report[column] === 'string' &&
            report[column].trim() === ''
          ) {
            this.addWarning(
              `#${logRecord} | ${column} | Celda vacía. Se ha reemplazado por nulo.`,
            )

            report[column] = null
            return
          }

          report[column] = parseFloat(report[column].trim().replace(',', '.'))

          if (isNaN(report[column])) {
            this.addWarning(
              `#${logRecord} | ${column} | Formato no válido. | Valor encontrado: ` +
                report[column],
            )
            report[column] = null
            return
          }

          // Convertir Caudal Ecológico a m3/s
          if (column === 'caudalEcologico') {
            report[column] = report[column] / 1000
          }
        } catch (error) {
          this.addError(
            `#${logRecord} | ${column} | Error no capturado: ${error}`,
          )
        }
      })

      intColumns.forEach(column => {
        try {
          if (!Object.prototype.hasOwnProperty.call(report, column)) {
            this.addWarning(`#${logRecord} | ${column} | No encontrada.`)
            report[column] = null
            return
          }

          if (typeof report[column] === 'number') return

          report[column] = parseInt(report[column])
        } catch (error) {
          this.addError(
            `#${logRecord} | ${column} | Error no capturado: ${error}`,
          )
        }
      })

      dateColumns.forEach(column => {
        try {
          if (!Object.prototype.hasOwnProperty.call(report, column)) {
            this.addWarning(`#${logRecord} | ${column} | No encontrada.`)
            report[column] = null
            return
          }

          let dateToParse = report[column].split('-')
          let dashDate = true

          if (dateToParse.length !== 3) {
            dashDate = false
            dateToParse = report[column].split('/')
          }

          if (dateToParse.length !== 3) {
            this.addWarning(
              `#${logRecord} | ${column} | Formato no válido. Use DD-MM-YYYY o MM/DD/YYYY. | Valor encontrado: ` +
                report[column],
            )
            report[column] = null
            return
          }

          let date

          if (dashDate) {
            date = new Date(
              parseInt(dateToParse[2].trim()),
              parseInt(dateToParse[1].trim()) - 1,
              parseInt(dateToParse[0].trim()),
            )
          } else {
            date = new Date(
              parseInt(dateToParse[2].trim()),
              parseInt(dateToParse[0].trim()) - 1,
              parseInt(dateToParse[1].trim()),
            )
          }

          if (date.toString() === 'Invalid Date') {
            this.addWarning(
              `#${logRecord} | ${column} | Formato no válido. Use DD-MM-YYYY o MM/DD/YYYY. | Valor encontrado: ` +
                report[column],
            )
            report[column] = null
            return
          }

          report[column] = date
        } catch (error) {
          this.addError(
            `#${logRecord} | ${column} | Error no capturado: ${error}`,
          )
        }
      })

      stringColumns.forEach(column => {
        try {
          if (!Object.prototype.hasOwnProperty.call(report, column)) {
            this.addWarning(`#${logRecord} | ${column} | No encontrada.`)
            report[column] = null

            return
          }

          if (
            typeof report[column] === 'string' &&
            report[column].trim() === ''
          ) {
            this.addWarning(
              `#${logRecord} | ${column} | Celda vacía. Se ha reemplazado por nulo.`,
            )

            report[column] = null
            return
          }

          report[column] = report[column].toString().trim()
        } catch (error) {
          this.addError(
            `#${logRecord} | ${column} | Error no capturado: ${error}`,
          )
        }
      })

      medicionesColumns.forEach(column => {
        try {
          if (!Object.prototype.hasOwnProperty.call(report, column)) {
            this.addWarning(`#${logRecord} | ${column} | No encontrada.`)
            report[column] = null
            return
          }

          if (
            typeof report[column] === 'string' &&
            report[column].trim() === ''
          ) {
            this.addWarning(
              `#${logRecord} | ${column} | Celda vacía. Se ha reemplazado por nulo.`,
            )

            report[column] = null
            return
          }

          if (typeof report[column] != 'number') {
            report[column] = parseFloat(report[column].trim().replace(',', '.'))
          }

          // Convertir mediciones a m3/s si corresponde
          if (report['unidadCaudal'] === 'Lt/s') {
            report[column] = report[column] / 1000
          }
        } catch (error) {
          this.addError(
            `#${logRecord} | ${column} | Error no capturado: ${error}`,
          )
        }
      })

      // Si no hay conversión, quiere decir que la data original está en WGS84
      // Por ende, usar esa
      // Si no, verificamos que todas las coordenadas convertidas estén presentes
      if (
        !Object.prototype.hasOwnProperty.call(
          report,
          'utmEsteCaptacionConversion',
        ) &&
        !Object.prototype.hasOwnProperty.call(
          report,
          'utmNorteCaptacionConversion',
        ) &&
        !Object.prototype.hasOwnProperty.call(report, 'husoCaptacionConversion')
      ) {
        report['utmEsteCaptacionConversion'] = report['utmEsteCaptacion']
        report['utmNorteCaptacionConversion'] = report['utmNorteCaptacion']
        report['husoCaptacionConversion'] = report['husoCaptacion']
        report['datumCaptacionConversion'] = report['datumCaptacion']
      } else {
        if (
          !Object.prototype.hasOwnProperty.call(
            report,
            'utmEsteCaptacionConversion',
          ) ||
          !Object.prototype.hasOwnProperty.call(
            report,
            'utmNorteCaptacionConversion',
          ) ||
          !Object.prototype.hasOwnProperty.call(
            report,
            'husoCaptacionConversion',
          )
        ) {
          this.addError(
            `#${logRecord} | Coordenadas UTM Convertidas incompletas`,
          )
          return
        }
      }

      // captacion segun contrato
      const utmCoords = {
        easting: report['utmEsteCaptacionConversion'],
        northing: report['utmNorteCaptacionConversion'],
        zoneNum: report['husoCaptacionConversion'],
        zoneLetter: 'H',
      }

      // const utmCoordsAforo = {
      //   easting: report['utmEsteAforo'],
      //   northing: report['utmNorteAforo'],
      //   zoneNum: report['husoAforo'],
      //   zoneLetter: 'H',
      // }

      if (utmCoords.easting && utmCoords.northing && utmCoords.zoneNum) {
        try {
          const { latitude, longitude } = utm.toLatLon(
            utmCoords.easting,
            utmCoords.northing,
            utmCoords.zoneNum,
            utmCoords.zoneLetter,
          )

          report['mapaLatitud'] = latitude
          report['mapaLongitud'] = longitude
        } catch (error) {
          this.addError(
            `#${logRecord} | Coordenadas UTM | Error no capturado: ${error}`,
          )
        }
      } else {
        report['mapaLatitud'] = null
        report['mapaLongitud'] = null
      }

      // if (
      //   utmCoordsAforo.easting &&
      //   utmCoordsAforo.northing &&
      //   utmCoordsAforo.zoneNum
      // ) {
      //   try {
      //     const { latitude: latitudeAforo, longitude: longitudeAforo } =
      //       utm.toLatLon(
      //         utmCoordsAforo.easting,
      //         utmCoordsAforo.northing,
      //         utmCoordsAforo.zoneNum,
      //         utmCoordsAforo.zoneLetter,
      //       )

      //     report['mapaLatitudAforo'] = latitudeAforo
      //     report['mapaLongitudAforo'] = longitudeAforo
      //   } catch (error) {
      //     this.addError(
      //       `#${logRecord} | Coordenadas UTM | Error no capturado: ${error}`,
      //     )
      //   }
      // } else {
      //   report['mapaLatitudAforo'] = null
      //   report['mapaLongitudAforo'] = null
      // }
    })

    // eslint-disable-next-line
    console.log(jsonData)

    return jsonData
  },

  async uploadReports(file) {
    if (!file) return

    this.isLoadingReportsSync = true

    const reader = new FileReader()
    reader.onload = e => {
      const arrayBuffer = e.target.result
      const data = new Uint8Array(arrayBuffer)
      const arr = new Array()
      for (let i = 0; i !== data.length; i++)
        arr[i] = String.fromCharCode(data[i])
      const bstr = arr.join('')

      const workbook = XLSX.read(bstr, { type: 'binary' })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]

      const fileHeader = [
        'numeroMedicion',
        'codigoExpediente',
        'numeroSolicitud',
        'region',
        'provincia',
        'comuna',
        'nombreSolicitante',
        'unidadResolucion',
        'fechaResolucion',
        'fechaTomaRazon',
        'articuloTransitorio',
        'tipoDerecho',
        'naturalezaDelAgua',
        'clasificacionFuente',
        'usoAgua',
        'cuenca',
        'subCuenca',
        'subSubCuenca',
        'fuenteAgua',
        'medicionEnero',
        'medicionFebrero',
        'medicionMarzo',
        'medicionAbril',
        'medicionMayo',
        'medicionJunio',
        'medicionJulio',
        'medicionAgosto',
        'medicionSeptiembre',
        'medicionOctubre',
        'medicionNoviembre',
        'medicionDiciembre',
        'ejercicioDerecho',
        'caudalAnualPromedio',
        'unidadCaudal',
        'caudalAnualPromedioM3S',
        'tieneCaudalPromedioAnual',
        'caudalEcologico',
        'tieneCaudalEcologicoPromedio',
        'utmNorteCaptacion',
        'utmEsteCaptacion',
        'husoCaptacion',
        'datumCaptacion',
        'utmNorteCaptacionConversion',
        'utmEsteCaptacionConversion',
        'husoCaptacionConversion',
        'datumCaptacionConversion',
        'cbr',
        'fojas',
        'numeroCBR',
        'año',
        'tieneRenuncia',
        'tieneTransacción',
        'fechaInspeccion',
        'utmNorteCaptacionTerreno',
        'utmEsteCaptacionTerreno',
        'husoCaptacionTerreno',
        'utmNorteAforo',
        'utmEsteAforo',
        'husoAforo',
        'ejerceDerecho',
        'existeObra',
        'tipoCaptacion',
        'usoCorroboradoTerreno',
        'caudalMedido',
        'tieneCumplimiento',
        'tieneRCA',
        'tieneCaudalAmbiental',
        'cumplimientoCaudalAmbiental',
        'observaciones',
      ]

      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: fileHeader,
        raw: false,
      })

      // eslint-disable-next-line
      console.log(jsonData)

      let importedReports = this._processJsonData(jsonData)

      this.importedReports = importedReports
    }

    reader.readAsArrayBuffer(file)

    this.isLoadingReportsSync = false
  },
  async updateReportsByTimestamp(oldTimestamp) {
    this.isLoading = true
    let timeNowChilean = moment.tz('America/Santiago')
    const timestamp = timeNowChilean.format()

    const reportsRef = collection(db, 'reports')
    const q = query(reportsRef, where('createdDate', '==', oldTimestamp))

    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(async doc => {
      await updateDoc(doc.ref, {
        createdDate: timestamp,
      })
    })
    await this.fetchReports()

    this.isLoading = false
  },
  async fetchReports() {
    this.isLoading = true
    this.markers = []

    try {
      const querySnapshot = await getDocs(collection(db, 'reports'))

      const reports = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }))

      // Agrupar reportes por createdDate
      const groupedReports = reports.reduce((acc, report) => {
        const date = report.createdDate
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(report)
        return acc
      }, {})

      // Obtener la fecha actual en la zona horaria de Chile
      const now = moment.tz('America/Santiago')

      // Encontrar el grupo más cercano a la hora actual
      const closestGroup = Object.keys(groupedReports).reduce(
        (closest, date) => {
          const diff = Math.abs(now.diff(moment(date)))
          if (diff < closest.diff) {
            return { date, diff, reports: groupedReports[date] }
          }
          return closest
        },
        { date: null, diff: Infinity, reports: [] },
      )

      // Actualizar el timestamp del grupo seleccionado
      this.selectGroupTimestamp = closestGroup.date

      // Agrupar los reportes vigentes por codigoExpediente
      const expedienteGroups = closestGroup.reports.reduce((acc, report) => {
        const codigoExpediente = report.dgaReport.codigoExpediente

        if (!acc[codigoExpediente]) {
          acc[codigoExpediente] = []
        }

        acc[codigoExpediente].push(report)
        return acc
      }, {})

      // const groupedReportsByExpediente = Object.keys(expedienteGroups).map(
      //   codigoExpediente => {
      //     const reportsForExpediente = expedienteGroups[codigoExpediente]

      //     reportsForExpediente.sort(
      //       (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      //     )

      //     // El más reciente es root
      //     const recentReport = reportsForExpediente[0]

      //     const allReports = reportsForExpediente
      //       .slice()
      //       .sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate))

      //     // Calcular porcentaje de cumplimiento y clasificación individual
      //     let cumpleCount = 0

      //     allReports.forEach(report => {
      //       const caudalMedido = report.dgaReport.caudalMedido
      //       //const fechaInspeccion = report.dgaReport.fechaInspeccion

      //       //const fecha = new Date(fechaInspeccion.seconds * 1000)
      //       // const mesInspeccion = fecha.toLocaleString('es-ES', {
      //       //   month: 'long',
      //       // })
      //       // const mesCamelCase =
      //       //   'medicion' +
      //       //   mesInspeccion.charAt(0).toUpperCase() +
      //       //   mesInspeccion.slice(1)

      //       //ajusta caudal esperado convertiriendo  caudal ecologico a m3/s

      //       const caudalEsperado = report.dgaReport.caudalEcologico
      //       //const caudalEsperado = report.dgaReport[mesCamelCase]

      //       if (caudalEsperado) {
      //         const porcentajeCumplimiento =
      //           (caudalMedido / caudalEsperado) * 100

      //         report.dgaReport.porcentajeCumplimiento =
      //           porcentajeCumplimiento.toFixed(2)

      //         if (porcentajeCumplimiento.toFixed(2) >= 120) {
      //           report.dgaReport.clasificacionIndividual = 'Cumple'
      //         }
      //         if (
      //           porcentajeCumplimiento.toFixed(2) >= 100 &&
      //           porcentajeCumplimiento.toFixed(2) < 120
      //         ) {
      //           report.dgaReport.clasificacionIndividual = 'Cumple mas o menos'
      //         }
      //         if (porcentajeCumplimiento.toFixed(2) < 100) {
      //           report.dgaReport.clasificacionIndividual = 'No Cumple'
      //         }

      //         //porcentaje de cumplimiento mayor a 120%
      //         if (porcentajeCumplimiento >= 120) {
      //           cumpleCount++
      //         }
      //       } else {
      //         report.dgaReport.porcentajeCumplimiento = null
      //       }
      //     })

      //     const totalReports = allReports.length
      //     let clasificacion = 'E'

      //     if (cumpleCount === totalReports) {
      //       clasificacion = 'A'
      //     } else if (cumpleCount > totalReports / 2) {
      //       clasificacion = 'B'
      //     } else if (cumpleCount === totalReports / 2) {
      //       clasificacion = 'C'
      //     } else if (cumpleCount >= totalReports * 0.1) {
      //       clasificacion = 'D'
      //     }

      //     recentReport.dgaReport.letraClasificacion = clasificacion

      //     const reportGroup = {
      //       rootReport: recentReport, // El reporte más reciente con la clasificación
      //       allReports,
      //     }

      //     return reportGroup
      //   },
      // )
      const groupedReportsByExpediente = Object.keys(expedienteGroups).map(
        codigoExpediente => {
          const reportsForExpediente = expedienteGroups[codigoExpediente]

          reportsForExpediente.sort(
            (a, b) =>
              new Date(b.dgaReport.fechaInspeccion.toDate()) -
              new Date(a.dgaReport.fechaInspeccion.toDate()),
          )

          const allReports = reportsForExpediente
            .slice()
            .sort(
              (a, b) =>
                new Date(b.dgaReport.fechaInspeccion.toDate()) -
                new Date(a.dgaReport.fechaInspeccion.toDate()),
            )

          const hasMeasuredFlow = allReports.some(
            report => report.dgaReport.caudalMedido !== null,
          )

          const filteredReports = hasMeasuredFlow
            ? allReports.filter(
                report => report.dgaReport.caudalMedido !== null,
              )
            : allReports

          let cumpleCount = 0

          filteredReports.forEach(report => {
            const caudalMedido = report.dgaReport.caudalMedido
            const caudalEsperado = report.dgaReport.caudalEcologico

            if (caudalEsperado && caudalMedido !== null) {
              const porcentajeCumplimiento =
                (caudalMedido / caudalEsperado) * 100

              report.dgaReport.porcentajeCumplimiento =
                porcentajeCumplimiento.toFixed(2)

              if (porcentajeCumplimiento >= 120) {
                report.dgaReport.clasificacionIndividual = 'Cumple'
                cumpleCount++
              } else if (porcentajeCumplimiento >= 100) {
                report.dgaReport.clasificacionIndividual = 'Cumple mas o menos'
              } else {
                report.dgaReport.clasificacionIndividual = 'No Cumple'
              }
            } else {
              report.dgaReport.porcentajeCumplimiento = null
              report.dgaReport.clasificacionIndividual =
                filteredReports.length === 1 ? 'N' : 'Excluido'
            }
          })

          const totalReports = filteredReports.length
          let clasificacion = 'E'

          if (cumpleCount === totalReports) {
            clasificacion = 'A'
          } else if (cumpleCount > totalReports / 2) {
            clasificacion = 'B'
          } else if (cumpleCount === totalReports / 2) {
            clasificacion = 'C'
          } else if (cumpleCount >= totalReports * 0.1) {
            clasificacion = 'D'
          } else if (
            filteredReports.length === 1 &&
            allReports[0].dgaReport.caudalMedido === null
          ) {
            clasificacion = 'N'
          } else if (cumpleCount === 0) {
            clasificacion = 'E'
          }

          const recentReport = reportsForExpediente[0]
          recentReport.dgaReport.letraClasificacion = clasificacion

          const reportGroup = {
            rootReport: recentReport,
            allReports: allReports,
          }

          return reportGroup
        },
      )
      this.reports = groupedReportsByExpediente.map(group => ({
        ...group.rootReport,
        allReports: group.allReports,
      }))
      //console.log(this.reports)
      // Cargar los marcadores
      this.markers = groupedReportsByExpediente.map(group => {
        const report = group.rootReport
        return {
          report: report,
          location: `${report.dgaReport.cuenca}`,
          type: '',
          positionAforo: {
            lat: 0,
            lng: 0,
          },
          position: {
            lat: report.dgaReport.mapaLatitud,
            lng: report.dgaReport.mapaLongitud,
          },
        }
      })

      // Almacenar los otros grupos en otra variable
      this.groupReports = Object.keys(groupedReports)
        .map(date => ({
          timestamp: date,
          reports: groupedReports[date],
        }))
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    } catch (error) {
      this.reports = []
    }

    this.isLoading = false
  },
  loadPositionAforo(report) {
    let searchIndexMarkerTemp = this.markers.findIndex(
      item => item.type === 'temporay',
    )

    if (searchIndexMarkerTemp !== -1) {
      this.markers.splice(searchIndexMarkerTemp, 1)
    }
    // convierte la localización de la captación a coordenadas
    if (report.dgaReport.utmNorteAforo) {
      const utmCoords = {
        easting: report.dgaReport.utmEsteAforo,
        northing: report.dgaReport.utmNorteAforo,
        zoneNum: report.dgaReport.husoAforo,
        zoneLetter: 'H',
      }

      try {
        const { latitude, longitude } = utm.toLatLon(
          utmCoords.easting,
          utmCoords.northing,
          utmCoords.zoneNum,
          utmCoords.zoneLetter,
        )
        this.markers.push({
          report: report,
          location: `Localizacion donde se realizo la medición en ${report.dgaReport.cuenca} `,
          type: 'temporary',
          positionAforo: {
            lat: latitude,
            lng: longitude,
          },
          position: {
            lat: latitude,
            lng: longitude,
          },
        })
      } catch (error) {
        this.addError(
          `#${report.dgaReport.codigoExpediente} | Coordenadas UTM | Error no capturado: ${error}`,
        )
      }
    }
  },
  deleteTemporaryMarker() {
    let searchIndexMarkerTemp = this.markers.findIndex(
      item => item.type === 'temporary',
    )
    if (searchIndexMarkerTemp !== -1) {
      this.markers.splice(searchIndexMarkerTemp, 1)
    }
  },
  filterReports(filters) {
    const {
      selectedItemFilter,
      selectFilterReport,
      isRCA,
      selectedTipoDerecho,
      selectedTipoUso,
      selectedClasificacion,
    } = filters

    this.markers = this.reports
      .filter(report => {
        let matchesFilter = true

        // Verificar filtro de selectFilterReport
        if (selectedItemFilter && selectedItemFilter.length > 0) {
          switch (selectFilterReport) {
            case 'Region':
              matchesFilter = selectedItemFilter.includes(
                report.dgaReport.region,
              )
              break
            case 'Comuna':
              matchesFilter = selectedItemFilter.includes(
                report.dgaReport.comuna,
              )
              break
            case 'Cuenca':
              matchesFilter = selectedItemFilter.includes(
                report.dgaReport.cuenca,
              )
              break
            case 'Subcuenca':
              matchesFilter = selectedItemFilter.includes(
                report.dgaReport.subCuenca,
              )
              break
            case 'Tipo de uso':
              matchesFilter = selectedItemFilter.includes(
                report.dgaReport.usoAgua,
              )
              break
            case 'Tipo de derecho':
              matchesFilter = selectedItemFilter.includes(
                report.dgaReport.tipoDerecho,
              )
              break
            default:
              matchesFilter = true
          }
        }

        // Verificar filtro de isRCA
        if (isRCA !== null) {
          matchesFilter = matchesFilter && report.dgaReport.tieneRCA === isRCA
        }
        // Verificar filtro de tipo de derecho
        if (selectedTipoDerecho !== null) {
          matchesFilter =
            matchesFilter &&
            report.dgaReport.tipoDerecho === selectedTipoDerecho
        }

        // Verificar filtro de tipo de uso
        if (selectedTipoUso !== null) {
          matchesFilter =
            matchesFilter && selectedTipoUso.includes(report.dgaReport.usoAgua)
        }

        // Verificar filtro de clasificacion
        if (selectedClasificacion !== null) {
          matchesFilter =
            matchesFilter &&
            selectedClasificacion === report.dgaReport.letraClasificacion
        }

        return matchesFilter
      })
      .map(report => ({
        report,
        location: `${report.dgaReport.cuenca}`,
        type: report.dgaReport.tipoDerecho || '',
        positionAforo: {
          lat: report.dgaReport.aforoLatitud || 0,
          lng: report.dgaReport.aforoLongitud || 0,
        },
        position: {
          lat: report.dgaReport.mapaLatitud,
          lng: report.dgaReport.mapaLongitud,
        },
      }))
  },
  selectReportsByTimestamp(timestamp) {
    const group = this.groupReports.find(group => group.timestamp === timestamp)
    if (group) {
      this.reports = group.reports
    } else {
      this.reports = []
    }
  },
  async updateReport(report) {
    this.isLoading = true
    await setDoc(doc(db, 'reports', report.id), report)
      .then(() => {
        this.isLoading = false
      })
      .catch(() => {
        alert('Error al actualizar el reporte')
        this.isLoading = false
      })
  },
}
