export const showString = value => {
  if (!value) return '-'
  return value
}

export const showNotes = value => {
  if (!value) return 'Sin observaciones'
  return value
}

export const showM3s = value => {
  if (!value) return '-'
  if (value === '-') return '-'
  return value + ' m³/s'
}

export const showDatum = value => {
  if (!value) return '-'
  if (value === 1984) return 'WGS-84'
  if (value === 1956) return 'PSAD-56'
  return value
}

export const showBool = value => {
  if (value === undefined) return '-'
  if (value === 'Si' || value === true) return 'Sí'
  if (value === 'No' || value === false) return 'No'
  return value
}

export const showDate = value => {
  if (!value) return '-'
  return new Date(value.toDate()).toLocaleDateString('es-CL')
}

export const showFloat = (value, fixed) => {
  if (value === undefined || value === null) return '-'
  return value.toFixed(fixed).replace('.', ',')
}

export const showUTM = (huso, norte, este) => {
  if (!huso || !norte || !este) return '-'
  return `${huso} H, ${este} m E, ${norte} m S`
}

export const showCumple = value => {
  switch (value) {
    case 'Cumple':
      return 'Sí'
    case 'No Cumple':
      return 'No'
    case 'Cumple mas o menos':
      return 'Más o menos'
    case 'Excluido':
      return 'No aplica'
    case 'N':
      return 'No aplica'
    default:
      return '-'
  }
}

export function dataNameMapping(varName) {
  if (typeof varName !== 'string' || !varName.length) {
    return ''
  }

  const cleaned = varName
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase())

  return cleaned
}

export function dataValueMapping(varName) {
  switch (varName) {
    case 'tieneRenuncia':
      return { name: 'Tiene Renuncia', group: '', visible: true, position: 0 }
    case 'tieneTransacción':
      return {
        name: 'Tiene Transacción',
        group: '',
        visible: true,
        position: 0,
      }
    case 'ejerceDerecho':
      return { name: 'Ejerce Derecho', group: '', visible: true, position: 0 }
    case 'existeObra':
      return { name: 'Existe Obra', group: '', visible: true, position: 0 }
    case 'tieneCumplimiento':
      return {
        name: 'Tiene Cumplimiento',
        group: '',
        visible: true,
        position: 0,
      }
    case 'tieneRCA':
      return { name: 'Tiene RCA', group: '', visible: true, position: 0 }
    case 'tieneCaudalAmbiental':
      return {
        name: 'Tiene Caudal Ambiental',
        group: '',
        visible: true,
        position: 0,
      }
    case 'tieneCaudalPromedioAnual':
      return {
        name: 'Tiene Caudal Promedio Anual',
        group: '',
        visible: true,
        position: 0,
      }
    case 'tieneCaudalEcologicoPromedio':
      return {
        name: 'Tiene Caudal Ecológico Promedio',
        group: '',
        visible: true,
        position: 0,
      }
    case 'medicionEnero':
      return { name: 'Medición Enero', group: '', visible: true, position: 0 }
    case 'medicionFebrero':
      return { name: 'Medición Febrero', group: '', visible: true, position: 0 }
    case 'medicionMarzo':
      return { name: 'Medición Marzo', group: '', visible: true, position: 0 }
    case 'medicionAbril':
      return { name: 'Medición Abril', group: '', visible: true, position: 0 }
    case 'medicionMayo':
      return { name: 'Medición Mayo', group: '', visible: true, position: 0 }
    case 'medicionJunio':
      return { name: 'Medición Junio', group: '', visible: true, position: 0 }
    case 'medicionJulio':
      return { name: 'Medición Julio', group: '', visible: true, position: 0 }
    case 'medicionAgosto':
      return { name: 'Medición Agosto', group: '', visible: true, position: 0 }
    case 'medicionSeptiembre':
      return {
        name: 'Medición Septiembre',
        group: '',
        visible: true,
        position: 0,
      }
    case 'medicionOctubre':
      return { name: 'Medición Octubre', group: '', visible: true, position: 0 }
    case 'medicionNoviembre':
      return {
        name: 'Medición Noviembre',
        group: '',
        visible: true,
        position: 0,
      }
    case 'medicionDiciembre':
      return {
        name: 'Medición Diciembre',
        group: '',
        visible: true,
        position: 0,
      }
    case 'caudalAnualPromedio':
      return {
        name: 'Caudal Anual Promedio',
        group: '',
        visible: true,
        position: 0,
      }
    case 'caudalAnualPromedioM3S':
      return {
        name: 'Caudal Anual Promedio M3S',
        group: '',
        visible: true,
        position: 0,
      }
    case 'caudalEcologico':
      return { name: 'Caudal Ecológico', group: '', visible: true, position: 0 }
    case 'caudalMedido':
      return { name: 'Caudal Medido', group: '', visible: true, position: 0 }
    case 'utmEsteAforo':
      return { name: 'UTM Este Aforo', group: '', visible: true, position: 0 }
    case 'utmEsteCaptacion':
      return {
        name: 'UTM Este Captación',
        group: '',
        visible: true,
        position: 0,
      }
    case 'utmEsteCaptacionConversion':
      return {
        name: 'UTM Este Captación Conversión',
        group: '',
        visible: true,
        position: 0,
      }
    case 'utmNorteAforo':
      return { name: 'UTM Norte Aforo', group: '', visible: true, position: 0 }
    case 'husoAforo':
      return { name: 'Huso Aforo', group: '', visible: true, position: 0 }
    case 'utmNorteCaptacion':
      return {
        name: 'UTM Norte Captación',
        group: '',
        visible: true,
        position: 0,
      }
    case 'utmNorteCaptacionConversion':
      return {
        name: 'UTM Norte Captación Conversión',
        group: '',
        visible: true,
        position: 0,
      }
    case 'utmEsteCaptacionTerreno':
      return {
        name: 'UTM Este Captación Terreno',
        group: '',
        visible: true,
        position: 0,
      }
    case 'utmNorteCaptacionTerreno':
      return {
        name: 'UTM Norte Captación Terreno',
        group: '',
        visible: true,
        position: 0,
      }
    case 'datumCaptacion':
      return { name: 'Datum Captación', group: '', visible: true, position: 0 }
    case 'husoCaptacion':
      return { name: 'Huso Captación', group: '', visible: true, position: 0 }
    case 'husoCaptacionTerreno':
      return {
        name: 'Huso Captación Terreno',
        group: '',
        visible: true,
        position: 0,
      }
    case 'husoCaptacionConversion':
      return {
        name: 'Huso Captación Conversión',
        group: '',
        visible: true,
        position: 0,
      }
    case 'numeroCBR':
      return { name: 'Número CBR', group: '', visible: true, position: 0 }
    case 'numeroMedicion':
      return { name: 'Número Medición', group: '', visible: true, position: 0 }
    case 'numeroSolicitud':
      return { name: 'Número Solicitud', group: '', visible: true, position: 0 }
    case 'fechaResolucion':
      return { name: 'Fecha Resolución', group: '', visible: true, position: 0 }
    case 'fechaTomaRazon':
      return { name: 'Fecha Toma Razón', group: '', visible: true, position: 0 }
    case 'fechaInspeccion':
      return { name: 'Fecha Inspección', group: '', visible: true, position: 0 }
    case 'codigoExpediente':
      return {
        name: 'Código Expediente',
        group: '',
        visible: true,
        position: 0,
      }
    case 'region':
      return { name: 'Región', group: '', visible: true, position: 0 }
    case 'provincia':
      return { name: 'Provincia', group: '', visible: true, position: 0 }
    case 'comuna':
      return { name: 'Comuna', group: '', visible: true, position: 0 }
    case 'nombreSolicitante':
      return {
        name: 'Nombre Solicitante',
        group: '',
        visible: true,
        position: 0,
      }
    case 'unidadResolucion':
      return {
        name: 'Unidad Resolución',
        group: '',
        visible: true,
        position: 0,
      }
    case 'articuloTransitorio':
      return {
        name: 'Artículo Transitorio',
        group: '',
        visible: true,
        position: 0,
      }
    case 'tipoDerecho':
      return { name: 'Tipo Derecho', group: '', visible: true, position: 0 }
    case 'naturalezaDelAgua':
      return {
        name: 'Naturaleza Del Agua',
        group: '',
        visible: true,
        position: 0,
      }
    case 'clasificacionFuente':
      return {
        name: 'Clasificación Fuente',
        group: '',
        visible: true,
        position: 0,
      }
    case 'usoAgua':
      return { name: 'Uso Agua', group: '', visible: true, position: 0 }
    case 'cuenca':
      return { name: 'Cuenca', group: '', visible: true, position: 0 }
    case 'subCuenca':
      return { name: 'Sub Cuenca', group: '', visible: true, position: 0 }
    case 'subSubCuenca':
      return { name: 'Sub Sub Cuenca', group: '', visible: true, position: 0 }
    case 'fuenteAgua':
      return { name: 'Fuente Agua', group: '', visible: true, position: 0 }
    case 'ejercicioDerecho':
      return {
        name: 'Ejercicio Derecho',
        group: '',
        visible: true,
        position: 0,
      }
    case 'unidadCaudal':
      return { name: 'Unidad Caudal', group: '', visible: true, position: 0 }
    case 'cbr':
      return { name: 'CBR', group: '', visible: true, position: 0 }
    case 'fojas':
      return { name: 'Fojas', group: '', visible: true, position: 0 }
    case 'año':
      return { name: 'Año', group: '', visible: true, position: 0 }
    case 'tipoCaptacion':
      return { name: 'Tipo Captación', group: '', visible: true, position: 0 }
    case 'usoCorroboradoTerreno':
      return {
        name: 'Uso Corroborado Terreno',
        group: '',
        visible: true,
        position: 0,
      }
    case 'cumplimientoCaudalAmbiental':
      return {
        name: 'Cumplimiento Caudal Ambiental',
        group: '',
        visible: true,
        position: 0,
      }
    default:
      return { name: varName, group: 'On Group', visible: true }
  }
}
