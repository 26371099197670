export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime() //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}
export function setChileanDateToString(firedate) {
  if (!firedate) return null

  const timestamp = new Date(
    firedate.seconds * 1000 + firedate.nanoseconds / 1000000,
  )
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ]

  const day = timestamp.getDate()
  const month = months[timestamp.getMonth()]
  const year = timestamp.getFullYear()

  const hour = String(timestamp.getHours()).padStart(2, '0')
  const minutes = String(timestamp.getMinutes()).padStart(2, '0')

  const setDate = `${day} de ${month} de ${year} a las ${hour}:${minutes} hrs`

  return setDate
}
export function setTextClasificacion(clasificacion) {
  switch (clasificacion) {
    case 'A':
      return 'Siempre cumple'
    case 'B':
      return 'Cumple más de lo que no cumple'
    case 'C':
      return 'Cumple mitad y mitad'
    case 'D':
      return 'Cumple menos de lo que  cumple'
    case 'E':
      return 'Nunca cumple'
    case 'N':
      return 'No aplica'
    default:
      return 'No aplica'
  }
}
