<script setup>
  import { use } from 'echarts/core'
  import { LineChart } from 'echarts/charts'
  import {
    TooltipComponent,
    GridComponent,
    MarkAreaComponent,
  } from 'echarts/components'
  import { CanvasRenderer } from 'echarts/renderers'
  import VChart, { THEME_KEY } from 'vue-echarts'
  import { ref, provide, watchEffect } from 'vue'
  import { showDate } from '../utils'

  use([
    TooltipComponent,
    GridComponent,
    MarkAreaComponent,
    LineChart,
    CanvasRenderer,
  ])

  provide(THEME_KEY, 'light')

  const cumplimientoCotaBaja = ref(0)
  const cumplimientoCotaMedia = ref(0)

  const props = defineProps({
    seriesData: Array,
    caudalEcologico: Number,
  })

  const showCaudal = value => {
    if (value === undefined || value === null) return '-'
    return value.toFixed(3).replace('.', ',') + ' m³/s'
  }

  const customSort = graphData => {
    if (!graphData) return []

    // Estamos construyendo un arreglo de arreglos
    // Formato: [.., [[row['fecha'], row['caudal']], ...]

    // Primero ordenamos por fecha
    graphData.sort((a, b) => {
      return new Date(a['fecha'].toDate()) - new Date(b['fecha'].toDate())
    })

    // Luego mapeamos a un arreglo de arreglos para el gráfico
    return graphData.map(row => [showDate(row['fecha']), row['caudal']])
  }

  const option = ref({
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        params = params[0]
        return (
          '<strong>' +
          params.value[0] +
          ':</strong> ' +
          showCaudal(params.value[1])
        )
      },
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [],
        type: 'line',
        smooth: true,
        markArea: {
          data: [
            [
              {
                yAxis: 0,
                itemStyle: {
                  color: 'rgba(255, 0, 0, 0.2)',
                },
              },
              {
                yAxis: cumplimientoCotaBaja,
              },
            ],
            [
              {
                yAxis: cumplimientoCotaBaja,
                itemStyle: {
                  color: 'rgba(255, 255, 0, 0.2)',
                },
              },
              {
                yAxis: cumplimientoCotaMedia,
              },
            ],
            [
              {
                yAxis: cumplimientoCotaMedia,
                itemStyle: {
                  color: 'rgba(0, 255, 0, 0.2)',
                },
              },
              {
                yAxis: 9999,
              },
            ],
          ],
        },
      },
    ],
  })

  watchEffect(() => {
    option.value.series[0].data = customSort(props.seriesData)

    cumplimientoCotaBaja.value = props.caudalEcologico
    cumplimientoCotaMedia.value = props.caudalEcologico * 1.2
  })
</script>

<template>
  <v-chart
    class="chart"
    :option="option"
  />
</template>

<style scoped>
  .chart {
    height: 400px;
  }
</style>
