<script setup>
  import { useAuthStore } from '../store'
  import { ref, computed } from 'vue'

  import { useThemeStore } from '@/stores/theme'

  const themeStore = useThemeStore()

  const currentTheme = computed(() => themeStore.currentTheme)

  const authStore = useAuthStore()
  //const checkbox = ref(false);
  const email = ref('')
  const password = ref('')

  const isLoading = ref(false)
  const isSignInDisabled = ref(false)

  const error = ref(false)

  const errorMessages = ref('')
  const errorProvider = ref(false)
  const errorProviderMessages = ref('')
  const showPassword = ref(false)

  const errorPassLogin = computed(() => authStore.errorPassLogin)
  const errorPassLoginMsg = computed(() => authStore.errorPassLoginMsg)
  const errorLoginMsg = computed(() => authStore.errorLoginMsg)

  function btnSubmit() {
    if (email.value && password.value) {
      isLoading.value = true
      isSignInDisabled.value = true
      authStore.signIn({ email: email.value, password: password.value })
    }
  }
  // function btnSignIn() {
  //   email.value = email.value.trim().replace(/\s+/g, '');
  //   authStore.signIn({ email: email.value, password: password.value });
  // }
  // function signInProvider() {
  // }

  function resetErrors() {
    error.value = false
    errorMessages.value = ''

    errorProvider.value = false
    errorProviderMessages.value = ''
  }
  // function signinGoogleAuth() {
  //   authStore.googleAuth();
  // }

  //control del tema
  function btnToggleTheme() {
    themeStore.toggleTheme()
  }
</script>
<template>
  <div class="auth">
    <div>
      <div class="my-4 d-flex justify-end">
        <v-btn
          v-if="currentTheme == 'myCustomDarkTheme'"
          elevation="0"
          class="mt-3 mx-2"
          @click="btnToggleTheme"
          icon="mdi-white-balance-sunny"
        />
        <v-btn
          v-if="currentTheme == 'myCustomLightTheme'"
          elevation="0"
          class="mt-3 mx-2"
          @click="btnToggleTheme"
          icon="mdi-moon-waning-crescent"
        />
      </div>
    </div>
    <v-card
      class="mx-3"
      elevation="0"
      width="480px"
    >
      <div
        class="card-padding text-center white elevation shadow-green border-radius-lg mx-4 my-8"
      >
        <v-img
          contain
          height="100px"
          src="/img/icons/aqfletter.svg"
        />
      </div>

      <div class="card-padding px-5">
        <v-form>
          <div class="flex-grow-1">
            <label class="text-sm text-body ms-1">EMAIL</label>
            <v-text-field
              v-model="email"
              :error="error"
              name="email"
              bg-color="bg-primary"
              variant="solo"
              @change="resetErrors"
            />
          </div>
          <div class="flex-grow-1">
            <label class="text-sm text-body ms-1">CONTRASEÑA</label>

            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :error="error"
              :error-messages="errorMessages"
              name="password"
              variant="solo"
              bg-color="bg-primary"
              @change="resetErrors"
              @click:append="showPassword = !showPassword"
            />
          </div>

          <v-alert
            v-if="errorLoginMsg"
            type="error"
            class="my-2"
          >
            {{ errorLoginMsg }}
          </v-alert>
          <v-btn
            class="my-5"
            :loading="isLoading"
            :disabled="isSignInDisabled"
            block
            x-large
            color="primary"
            density="default"
            size="large"
            @click="btnSubmit()"
          >
            Entrar
          </v-btn>
          <v-alert
            v-if="errorPassLogin"
            border="right"
            colored-border
            type="error"
            elevation="2"
            class="mt-1"
          >
            {{ errorPassLoginMsg }}
          </v-alert>
        </v-form>
      </div>
    </v-card>
  </div>
</template>

<style>
  .auth {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  label {
    font-weight: 600;
  }
</style>
