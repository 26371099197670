<script setup>
  import { computed, ref } from 'vue'
  import { useReportsStore } from '../store'
  import { useThemeStore } from '@/stores/theme'
  import { useAuthStore } from '@/views/Authenticaction/store'
  import { setChileanDateToString, setTextClasificacion } from '@/config/utils'
  import { watch } from 'vue'
  import ExportReports from './ExportReports.vue'

  import { showString, showFloat, showM3s } from '../utils'

  //store
  const reportsStore = useReportsStore()
  const themeStore = useThemeStore()
  const authStore = useAuthStore()

  //data
  const activeShowFilters = ref(false)
  const filterTypes = [
    'Comuna',
    'Region',
    'Cuenca',
    'Subcuenca',
    // 'Tipo de uso',
    // 'Tipo de derecho',
  ]
  const selectFilterReport = ref('')

  const itemsFilterSelected = ref([])
  const selectedItemFilter = ref([])
  const selectedTipoDerecho = ref(null)
  const selectedTipoUso = ref(null)
  const selectedClasificacion = ref(null)
  const isCumplimiento = ref(null)
  const isRCA = ref(null)
  const isSatelital = ref(true)
  //computed
  let currentTheme = computed(() => themeStore.currentTheme)

  const search = computed({
    get: () => reportsStore.search,
    set: value => (reportsStore.search = value),
  })
  const filteredReports = computed(() => {
    if (
      selectedItemFilter.value.length > 0 ||
      isRCA.value !== null ||
      selectedTipoDerecho.value !== null ||
      selectedTipoUso.value !== null ||
      selectedClasificacion.value !== null
    ) {
      return reportsStore.reports.filter(report => {
        let matchesFilter = true

        if (selectedItemFilter.value.length > 0) {
          switch (selectFilterReport.value) {
            case 'Region':
              matchesFilter = selectedItemFilter.value.includes(
                report.dgaReport.region,
              )
              break
            case 'Comuna':
              matchesFilter = selectedItemFilter.value.includes(
                report.dgaReport.comuna,
              )
              break
            case 'Cuenca':
              matchesFilter = selectedItemFilter.value.includes(
                report.dgaReport.cuenca,
              )
              break
            case 'Subcuenca':
              matchesFilter = selectedItemFilter.value.includes(
                report.dgaReport.subCuenca,
              )
              break
            case 'Tipo de uso':
              matchesFilter = selectedItemFilter.value.includes(
                report.dgaReport.usoAgua,
              )
              break
            case 'Tipo de derecho':
              matchesFilter = selectedItemFilter.value.includes(
                report.dgaReport.tipoDerecho,
              )
              break
            default:
              matchesFilter = true
          }
        }
        // Verificar filtro de tipo de derecho
        if (selectedTipoDerecho.value !== null) {
          matchesFilter =
            matchesFilter &&
            report.dgaReport.tipoDerecho === selectedTipoDerecho.value
        }
        // // Verificar filtro de isCumplimiento
        // if (isCumplimiento.value !== null) {
        //   matchesFilter =
        //     matchesFilter &&
        //     report.dgaReport.tieneCumplimiento === isCumplimiento.value
        // }
        // Verificar filtro rca
        if (isRCA.value !== null) {
          matchesFilter =
            matchesFilter && report.dgaReport.tieneRCA === isRCA.value
        }
        // Verificar filtro de tipo de uso
        if (selectedTipoUso.value !== null) {
          matchesFilter =
            matchesFilter &&
            selectedTipoUso.value.includes(report.dgaReport.usoAgua)
        }
        if (selectedClasificacion.value !== null) {
          matchesFilter =
            matchesFilter &&
            selectedClasificacion.value == report.dgaReport.letraClasificacion
        }

        return matchesFilter
      })
    } else {
      return reportsStore.getReportsByLocation()
    }
  })

  //methods
  const btnViewReportById = report => {
    reportsStore.showReportDetails()

    reportsStore.setCurrentReport(report)
  }
  function btnToggleTheme() {
    themeStore.toggleTheme()
  }
  function cleanAllSelectedFilters() {
    selectedItemFilter.value = []
    selectFilterReport.value = ''
    // isCumplimiento.value = null
    selectedTipoDerecho.value = null
    isRCA.value = null
    selectedTipoUso.value = null
    selectedClasificacion.value = null
  }
  function hideFilters() {
    activeShowFilters.value = false
  }
  function seachItemsFilter(filter) {
    let items = []
    reportsStore.reports.forEach(report => {
      if (!items.includes(report.dgaReport[filter])) {
        items.push(report.dgaReport[filter])
      }
    })
    return items
  }

  // watchers
  watch(selectFilterReport, value => {
    if (value) {
      switch (value) {
        case 'Region':
          itemsFilterSelected.value = []
          reportsStore.reports.forEach(report => {
            if (!itemsFilterSelected.value.includes(report.dgaReport.region)) {
              itemsFilterSelected.value.push(report.dgaReport.region)
            }
          })
          break
        case 'Comuna':
          itemsFilterSelected.value = []
          reportsStore.reports.forEach(report => {
            if (!itemsFilterSelected.value.includes(report.dgaReport.comuna)) {
              itemsFilterSelected.value.push(report.dgaReport.comuna)
            }
          })
          break
        case 'Cuenca':
          itemsFilterSelected.value = []
          reportsStore.reports.forEach(report => {
            if (!itemsFilterSelected.value.includes(report.dgaReport.cuenca)) {
              itemsFilterSelected.value.push(report.dgaReport.cuenca)
            }
          })
          break
        case 'Subcuenca':
          itemsFilterSelected.value = []
          reportsStore.reports.forEach(report => {
            if (
              !itemsFilterSelected.value.includes(report.dgaReport.subCuenca)
            ) {
              itemsFilterSelected.value.push(report.dgaReport.subCuenca)
            }
          })
          break
        case 'Tipo de uso':
          itemsFilterSelected.value = []
          reportsStore.reports.forEach(report => {
            if (!itemsFilterSelected.value.includes(report.dgaReport.usoAgua)) {
              itemsFilterSelected.value.push(report.dgaReport.usoAgua)
            }
          })
          break
        case 'Tipo de derecho':
          itemsFilterSelected.value = []
          reportsStore.reports.forEach(report => {
            if (
              !itemsFilterSelected.value.includes(report.dgaReport.tipoDerecho)
            ) {
              itemsFilterSelected.value.push(report.dgaReport.tipoDerecho)
            }
          })
          break
      }
      // Limpiar selectedItemFilter
      selectedItemFilter.value = ''
    }
  })
  watch(
    [
      selectFilterReport,
      selectedItemFilter,
      isCumplimiento,
      isRCA,
      selectedTipoDerecho,
      selectedTipoUso,
      selectedClasificacion,
    ],
    () => {
      reportsStore.filterReports({
        selectedItemFilter: selectedItemFilter.value,
        selectFilterReport: selectFilterReport.value,
        isRCA: isRCA.value,
        selectedTipoDerecho: selectedTipoDerecho.value,
        selectedTipoUso: selectedTipoUso.value,
        selectedClasificacion: selectedClasificacion.value,
      })
    },
  )
  watch(isSatelital, value => {
    reportsStore.togleChangeMapType(value)
  })
</script>

<template>
  <v-navigation-drawer
    color="background"
    width="450"
    location="right"
  >
    <div class="d-flex">
      <v-text-field
        v-model="search"
        class="mx-4 mt-4"
        density="comfortable"
        menu-icon=""
        placeholder="Buscar por comuna"
        item-title="location"
        prepend-inner-icon="mdi-magnify"
        append-icon="mdi-filter"
        @click:append="activeShowFilters = !activeShowFilters"
        style="max-width: 350px"
        theme="light"
        variant="solo"
        auto-select-first
        item-props
        rounded
      />

      <v-btn
        v-if="currentTheme == 'myCustomDarkTheme'"
        elevation="0"
        class="mt-3 mx-2"
        @click="btnToggleTheme"
        icon="mdi-white-balance-sunny"
      />

      <v-btn
        v-if="currentTheme == 'myCustomLightTheme'"
        elevation="0"
        class="mt-3 mx-2"
        @click="btnToggleTheme"
        icon="mdi-moon-waning-crescent"
      />

      <v-btn
        v-if="authStore.userProfile.type == 'team'"
        icon="mdi-database"
        class="mt-3 mx-2"
        elevation="0"
        to="/reports"
      />

      <v-btn
        elevation="0"
        class="mt-3 mx-2"
        @click="authStore.logOut()"
        icon="mdi-exit-to-app"
      />
    </div>
    <div class="d-flex justify-end">
      <h4 class="mt-4 ml-5">
        {{ filteredReports.length }}
        {{ filteredReports.length === 1 ? 'Localización' : 'Localizaciones' }}
      </h4>
      <ExportReports :filteredReports="filteredReports" />
      <v-spacer />
      <v-switch
        class="mx-5"
        v-model="isSatelital"
        color="primary"
        label="Mapa Satelital"
        :value="true"
        hide-details
      ></v-switch>
    </div>
    <!-- filtros -->
    <div v-if="activeShowFilters">
      <div
        v-if="activeShowFilters"
        class="d-flex"
      >
        <v-spacer />
        <v-btn
          variant="text"
          @click="hideFilters"
          >Ocultar filtros</v-btn
        >
      </div>
      <div class="d-flex">
        <v-select
          v-model="selectFilterReport"
          class="mx-4 mt-2"
          label="Tipo de filtro"
          variant="solo"
          :items="filterTypes"
        />
        <v-btn
          class="mt-3 mr-2"
          v-tooltip:start="'Borrar filtros'"
          icon="mdi-filter-remove"
          @click="cleanAllSelectedFilters"
        ></v-btn>
      </div>

      <div
        v-if="selectFilterReport != '' && selectFilterReport != 'Cumplimiento'"
      >
        <v-autocomplete
          v-model="selectedItemFilter"
          class="mx-4 mt-2"
          label="Seleccionar"
          variant="solo"
          multiple
          :items="itemsFilterSelected"
        />
      </div>

      <!-- <div>
        <h5 class="text-center">Cumplimiento</h5>

        <v-radio-group
          class="d-flex justify-center"
          v-model="isCumplimiento"
          inline
        >
          <v-radio
            label="Sí, cumple"
            :value="true"
          ></v-radio>
          <v-radio
            label="No cumple"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </div> -->
      <!-- cumplimiento -->
      <v-divider class="mx-4 my-1"></v-divider>
      <div>
        <h5 class="text-center">Niveles de cumplimiento</h5>
        <!-- definir funcion  -->
        <v-radio-group
          v-model="selectedClasificacion"
          class="d-flex justify-center"
        >
          <v-radio
            label="A Siempre cumple"
            value="A"
          ></v-radio>
          <v-radio
            label="B Cumple más de lo que no cumple"
            value="B"
          ></v-radio>
          <v-radio
            label="C Cumple mitad y mitad"
            value="C"
          ></v-radio>
          <v-radio
            label="D Cumple menos de lo que cumple"
            value="D"
          >
          </v-radio>
          <v-radio
            label="E Nunca cumple"
            value="E"
          >
          </v-radio>
          <v-radio
            label="No aplica"
            value="N"
          >
          </v-radio>
        </v-radio-group>
      </div>
      <v-divider class="mx-4 my-1"></v-divider>
      <div>
        <h5 class="text-center">RCA</h5>

        <v-radio-group
          class="d-flex justify-center"
          v-model="isRCA"
          inline
        >
          <v-radio
            label="Sí"
            :value="true"
          ></v-radio>
          <v-radio
            label="No "
            :value="false"
          ></v-radio>
        </v-radio-group>
      </div>
      <v-divider class="mx-4 my-1"></v-divider>
      <!-- tipo de derecho -->
      <div class="my-1">
        <h5 class="text-center">Tipo de derecho</h5>

        <v-radio-group
          class="d-flex justify-center"
          v-model="selectedTipoDerecho"
          inline
        >
          <v-radio
            v-for="(valueItem, index) in seachItemsFilter('tipoDerecho')"
            :key="index"
            :label="valueItem"
            :value="valueItem"
          >
          </v-radio>
        </v-radio-group>
      </div>
      <v-divider class="mx-4 my-1"></v-divider>
      <!-- tipo de uso -->
      <div class="my-2">
        <h5 class="text-center">Tipo de uso</h5>

        <v-autocomplete
          v-model="selectedTipoUso"
          class="mx-4 mt-2"
          label="Seleccionar tipo de uso"
          variant="solo"
          multiple
          :items="seachItemsFilter('usoAgua')"
        />
      </div>
    </div>
    <!-- listado con los reportes -->
    <v-card
      height="82vh"
      color="background"
      class="overflow-auto"
      elevation="0"
    >
      <v-card
        v-for="report in filteredReports"
        :key="report.id"
        class="mx-3 my-4"
      >
        <v-card-item :title="showString(report.dgaReport.codigoExpediente)">
          <template v-slot:subtitle>
            <!-- codigo de expendiente -->
            <div class="mb-2">
              <div>
                <v-icon
                  class="me-1 pb-1"
                  icon="mdi-waves"
                  size="18"
                ></v-icon>
                {{ showString(report.dgaReport.subCuenca) }}
              </div>
            </div>
            <div class="mb-2">
              <div>
                <v-icon
                  class="me-1 pb-1"
                  icon="mdi-calendar"
                  size="18"
                ></v-icon>
                Última medición <br />
                {{ setChileanDateToString(report.dgaReport.fechaInspeccion) }}
              </div>
            </div>
            <div>
              <v-avatar
                size="20"
                class="mr-1"
              >
                <v-img
                  :src="`/img/icons/${report.dgaReport.letraClasificacion}.svg`"
                ></v-img>
              </v-avatar>

              {{ setTextClasificacion(report.dgaReport.letraClasificacion) }}
            </div>

            <div class="mb-1">
              {{ report.allReports.length }} medicion(es) realizada(s)
            </div>
          </template>
        </v-card-item>

        <v-card-text class="py-0">
          <div>
            <h5>Tipo de uso: {{ showString(report.dgaReport.usoAgua) }}</h5>
          </div>
        </v-card-text>

        <div class="d-flex py-3">
          <v-list-item
            density="compact"
            prepend-icon="mdi-water"
          >
            <v-list-item-subtitle class="">
              <strong>Caudal Medido:</strong>
              {{ showM3s(showFloat(report.dgaReport.caudalMedido, 3)) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="">
              <strong>Caudal Ecológico:</strong>
              {{ showM3s(showFloat(report.dgaReport.caudalEcologico, 3)) }}
            </v-list-item-subtitle>
          </v-list-item>
        </div>

        <v-divider />

        <v-card-actions>
          <v-btn
            text="Ver reporte"
            @click="btnViewReportById(report)"
          />
        </v-card-actions>
      </v-card>
      <div>
        <v-alert
          v-if="filteredReports.length == 0"
          class="mx-4 my-5"
          color="error"
          dense
          border="left"
          elevation="0"
        >
          No se encontraron resultados
        </v-alert>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>
