<script setup>
  import LoadingCircle from '@/components/common/LoadingCircle.vue'
  import { useReportsStore } from '../store'
  import { computed, ref } from 'vue'
  import { storeToRefs } from 'pinia'

  const file = ref(null)
  const reportsStore = useReportsStore()

  const { isLoadingReportsSync, importedReports, errorQueue, warningQueue } =
    storeToRefs(reportsStore)

  const isImportedReportsNotEmpty = computed(
    () => reportsStore.importedReports.length > 0,
  )

  const isErrorQueueNotEmpty = computed(
    () => reportsStore.errorQueue.length > 0,
  )

  const isWarningQueueNotEmpty = computed(
    () => reportsStore.warningQueue.length > 0,
  )

  const toggleReportsSync = () => {
    reportsStore.toggleReportsSync()
  }
</script>

<template>
  <v-navigation-drawer
    width="600"
    location="left"
  >
    <v-divider />

    <div
      v-show="isLoadingReportsSync"
      class="text-center"
    >
      <LoadingCircle />
    </div>

    <div v-show="!isLoadingReportsSync">
      <v-card
        class="mx-auto"
        max-width="90%"
        elevation="0"
      >
        <div class="d-flex justify-end">
          <v-icon
            class="ma-3"
            @click="toggleReportsSync"
          >
            mdi-close
          </v-icon>
        </div>

        <v-divider />

        <v-card-item>
          <template v-slot:title> Importar reportes </template>

          <v-row align="center">
            <v-col
              cols="auto"
              class="flex-grow-1"
              style="max-width: 80%"
            >
              <v-file-input
                v-model="file"
                prepend-icon="mdi-paperclip"
                accept=".xlsx"
                label="Seleccionar XLSX"
                class="pt-4"
                outlined
                dense
              />
            </v-col>

            <v-col cols="auto">
              <v-btn
                color="primary"
                @click="reportsStore.uploadReports(file)"
              >
                Importar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-item>

        <v-card-item v-if="isImportedReportsNotEmpty">
          <template v-slot:title> Reportes Importados</template>

          <v-row align="center">
            <v-col
              cols="auto"
              class="flex-grow-1"
              style="max-width: 80%"
            >
              {{ importedReports.length }} reporte(s)
            </v-col>
          </v-row>

          <v-row
            v-if="isWarningQueueNotEmpty"
            align="center"
          >
            <v-col cols="auto">
              <v-alert
                title="Advertencias"
                type="warning"
                variant="outlined"
              >
                <ul class="warning-list">
                  <li
                    v-for="(warning, index) in warningQueue"
                    :key="index"
                  >
                    - {{ warning }}
                  </li>
                </ul>
              </v-alert>
            </v-col>
          </v-row>

          <v-row
            v-if="isErrorQueueNotEmpty"
            align="center"
          >
            <v-col cols="auto">
              <v-alert
                title="Errores"
                type="error"
                variant="outlined"
              >
                <ul class="error-list">
                  <li
                    v-for="(error, index) in errorQueue"
                    :key="index"
                  >
                    - {{ error }}
                  </li>
                </ul>
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            v-if="!isErrorQueueNotEmpty"
            align="center"
          >
            <v-col
              class="flex-grow-1"
              style="max-width: 80%"
            >
              <v-alert
                title="Ningún error encontrado"
                type="success"
                variant="outlined"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                @click="reportsStore.overwriteReports()"
              >
                Sobrescribir
              </v-btn>
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<style scoped>
  .error-list {
    list-style-type: disc;
    padding: 0;
  }

  .error-list li {
    text-overflow: ellipsis;
    padding-top: 5px;
  }

  .warning-list {
    list-style-type: disc;
    padding: 0;
  }

  .warning-list li {
    text-overflow: ellipsis;
    padding-top: 5px;
  }
</style>
