import { db, auth } from '@/config/firebase'

import { doc, getDoc } from 'firebase/firestore'
import { signInWithEmailAndPassword } from 'firebase/auth'
import router from '@/router'
function manangeErrorLogin(error) {
  let status = ''
  switch (error.code) {
    case 'auth/user-not-found':
      status =
        'El correo electrónico no está registrado. Por favor, regístrate.'
      break
    case 'auth/wrong-password':
      status = 'La contraseña es incorrecta. Verifica tus credenciales.'
      break
    case 'auth/too-many-requests':
      status = 'Su cuenta ha sido desactivada temporalmente.'
      break
    default:
      status =
        'Se ha producido un error al iniciar sesión. Por favor, inténtalo de nuevo más tarde.'
  }
  return status
}

async function getUserProfile(userId) {
  const docRef = doc(db, 'users', userId)
  const docSnap = await getDoc(docRef)
  let userProfile = undefined
  if (docSnap.exists()) {
    userProfile = docSnap.data()
  }
  return userProfile
}
export default {
  async signIn(user) {
    try {
      const usuario = await signInWithEmailAndPassword(
        auth,
        user.email,
        user.password,
      )

      let userLogin = {
        uid: usuario.user.uid,
        email: usuario.user.email,
        emailVerified: usuario.user.emailVerified,
      }

      this.user = userLogin

      let profile = await getUserProfile(userLogin.uid)
      if (profile.type == 'team') {
        router.push('/reports')
      } else {
        router.push('/')
      }
      let newObjectJSON = JSON.stringify({
        name: profile.name,
        role: profile.role,
        email: userLogin.email,
        uid: userLogin.uid,
      })
      localStorage.setItem('user', newObjectJSON)
      this.actionAddUserProfile({ userProfile: profile })
    } catch (error) {
      let status = manangeErrorLogin(error)

      this.errorLoginMsg = status
    }
  },

  detectUser(user) {
    this.user = user
  },
  logOut() {
    auth.signOut().then(() => {
      router.push('/auth/login')
    })
  },
  updateOfflineStatus(status) {
    this.isOffline = status
  },
  actionAddUserProfile(data) {
    this.userProfile = data.userProfile
  },
}
