export default () => ({
  isLoading: false,
  isLoadingReportsSync: false,
  dynamicZoom: 7,
  isLoadingUpdateReport: false,
  errorQueue: [],
  warningQueue: [],
  reports: [],
  importedReports: [],
  currentReport: null,
  groupReports: [],
  search: '',
  mapType: 'satellite',
  selectGroupTimestamp: null,
  isReportsListVisible: true,
  isReportDetailsVisible: false,
  isReportsSyncVisible: false,
  center: { lat: 0, lng: 0 },
  markers: [],
})
