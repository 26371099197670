import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia'
import VueGoogleMaps from 'vue-google-maps-community-fork'
import { auth, db } from './config/firebase'
import { onSnapshot, getDoc, doc } from 'firebase/firestore'

loadFonts()

const pinia = createPinia()

let app

auth.onAuthStateChanged(async user => {
  if (navigator.onLine) {
    // El dispositivo está en línea
    initializeApp(user)
  } else {
    // El dispositivo está fuera de línea
    user = {
      email: 'hola@gonzalohonorato.cl',
      uid: 'Otk8sAvwjSWUzzIhx0BOkF9tHVq1',
      emailVerified: true,
    }
    initializeApp(user)
  }
})

async function initializeApp(user) {
  if (!app) {
    app = createApp(App)
    app.use(pinia)
    app.use(VueGoogleMaps, {
      load: {
        key: 'AIzaSyCU52JAqJjIA7LzQLg76T9yKXJB1htKY_o',
      },
    })
    app.use(router)
    app.use(vuetify)
    app.mount('#app')
  }

  const { useAuthStore } = await import('./views/Authenticaction/store')
  const authStore = useAuthStore()

  if (user) {
    const userDetec = {
      email: user.email,
      uid: user.uid,
      emailVerified: user.emailVerified,
    }
    if (navigator.onLine) {
      // El dispositivo está en línea
      let userProfile = await getUserProfile(userDetec.uid, authStore)
      authStore.actionAddUserProfile({ userProfile })
    } else {
      let userData = localStorage.getItem('user')
      let userLocalProfile = userData
        ? JSON.parse(userData)
        : { name: '', uid: '', role: '', email: '' }

      if (userLocalProfile.uid) {
        authStore.actionAddUserProfile({ userProfile: userLocalProfile })
      } else {
        alert('Hay un error al recuperar el usuario desde el dispositivo')
        return
      }
    }

    // Agregar aquí cualquier otra inicialización que dependa del usuario autenticado
  } else {
    authStore.detectUser(null)
  }
}

async function getActiveSesion(userId, authStore) {
  await onSnapshot(doc(db, 'users', userId), async doc => {
    if (!doc.data().active) {
      alert('Su sesión ha expirado')
      authStore.logOut()
    }
  })
}

async function getUserProfile(userId, authStore) {
  const docRef = doc(db, 'users', userId)
  const docSnap = await getDoc(docRef)
  let userProfile = undefined
  if (docSnap.exists()) {
    userProfile = docSnap.data()
    getActiveSesion(userId, authStore)
  }
  return userProfile
}
