export default {
  getReportById: state => id => {
    return state.reports.find(report => report.id === id)
  },

  getReportsByLocation: state => () => {
    if (state.search === '') return state.reports
    return state.reports.filter(report =>
      report.location.toLowerCase().includes(state.search.toLowerCase()),
    )
  },
}
