<template>
  <div class="progress-container">
    <v-progress-circular
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<style>
  .progress-container {
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
</style>
