import { defineStore } from 'pinia'

import ReportsState from './reportsState'
import ReportsActions from './reportsActions'
import ReportsGetters from './reportsGetters'

export const useReportsStore = defineStore('reports', {
  state: ReportsState,
  actions: ReportsActions,
  getters: ReportsGetters,
})
